import create from 'zustand';
import { devtools } from 'zustand/middleware';
import nativeAxios from 'axios';
import _get from 'lodash/get';

import { EnumMode, useAuth } from 'store';

export interface IUseDBType {
  error?: boolean;
  status?: number;
  message?: string;
  data?: object;
  setToken?: (token: string) => void;
}

export interface IUseDBAlert {
  status: number;
  message: string;
  identity: string;
  title: string;
  details?: any;
  error?: any;
}

export interface IUseDB {
  loading: boolean;
  headers: object;
  request: object;
  logout: boolean;
  status: number;
  alert?: IUseDBAlert;
  clearAlert: () => void;
  sessionExpired: boolean;
  token?: string;
  mode?: string;
  setToken: (token: string) => void;
  setLoading: (token: boolean) => void;
  axios: (obj: object, shouldSetAlert?: boolean) => any;
  setLogout: (flag: boolean) => void;
}

export const axios = async (arg: any, opts: any): Promise<IUseDBType> => {
  const { set, get } = opts;
  const { token: stateToken, setLogout, mode } = get();

  const token = String(stateToken).trim();

  try {
    const server =
      arg && arg.server === 'VAULT'
        ? process.env.REACT_APP_VAULT_SERVER
        : process.env.REACT_APP_PAYGATE_SERVER;

    set({ loading: true });

    const args = {
      method: 'GET',
      data: {},
      ...arg,
    };

    // if (args.server === 'VAULT') {
    //   args.headers = {
    //     'X-API-KEY': process.env.REACT_APP_VAULT_APIKEY,
    //   };
    // } else
    //   if (args.server === 'PAYGATE_API') {
    //   //PayGate Via API KEY (Used for Widget Demo)
    //   args.headers = { 'x-api-key': process.env.REACT_APP_PAYGATE_APIKEY };
    // } else {
      //Paygate Use Login
      // if (mode === EnumMode.API) args.headers = { 'x-api-key': token };
      // else args.headers = { 'x-user-token': token };
        if (mode !== EnumMode.LOGIN) args.headers = { 'Authorization': "Bearer " + token };
    // }

    args.url = server + arg.url;
    const result = await nativeAxios(args);

    //Set db Values after call
    set({ headers: result.headers });
    set({ request: result.request });
    set({ status: result.status });
    set({ loading: false });

    return {
      error: result.data.error || result.status !== 200,
      status: result.status,
      message: result.data.message || '',
      data: result.data || [],
    };
    //return retObj
  } catch (e: any) {
    const status = _get(e, 'response.status', 418) || 502;

    if (status === 403 || status === 401) {
      // IF API MODE AND FORBIDDEN ERROR THEN RELOAD WITH ?api PARAMATER
      setLogout(true);
      if (mode === EnumMode.API) {
        set({ sessionExpired: true });
      }
    }

    const errObj = {
      error: true,
      message: _get(
        e,
        'response.data.message',
        _get(
          e,
          'response.message',
          _get(
            e,
            'message',
            status === 404 ? 'Invalid API Route ' + arg.method + ':' + arg.url : 'Unknown Error',
          ),
        ),
      ),
      status: status,
      data: _get(e, 'response.data', []),
    };

    set({ loading: false });

    return errObj;
  }
};

export const useDB = create<IUseDB>()(
  devtools(
    (set, get) => ({
      status: 0,
      token: '',
      headers: {},
      request: {},
      logout: false,
      loading: false,
      alert: {
        status: 0,
        message: '',
        identity: '',
        title: '',
      },
      mode: '',
      sessionExpired: false,
      setLoading: (flag: boolean) => set({ loading: flag }),
      setAlert: (obj: IUseDBAlert) => set({ alert: obj }),
      setToken: (token: string) => set({ token: token }),
      setLogout: (flag: boolean) => set({ logout: flag }),
      clearAlert: () => set({ alert: { status: 0, message: '', identity: '', title: '' } }),
      axios: async (obj: object, shouldSetAlert: boolean = true) => {
        // check for token in persisted auth store before starting the query
        if (!get().token) {
          const token = useAuth.getState().user?.token;
          if (token) {
            set({ token });
          }
        }

        if (!get().mode) {
          const mode = useAuth.getState().user?.Mode;
          if (mode) {
            set({ mode });
          }
        }

        const result: any = await axios(obj, { set, get });

        // SHOW ALERT FOR ANY ERRORS
        if (result && result.error && shouldSetAlert) set({ alert: result });
        return result;
      },
    }),
    {
      name: 'paygate-db',
    },
  ),
);
