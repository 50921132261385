import { useMemo, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { GridItem, Button, Box } from '@chakra-ui/react';
import { FaCheck, FaTimes, FaCloudDownloadAlt } from 'react-icons/fa';
import _get from 'lodash/get';
import { saveAs } from 'file-saver';

import CRUD, {
  useCRUD,
  Table,
  FilterInputSearch,
  FilterInputSelect,
  Filters,
  BtnRefresh,
} from 'Library/CRUD';
import { getSelect, copyClipboard, right } from 'Library';
import { useDB, useStore } from 'store';
import { Alert } from 'components';
import { useAlert } from 'utils';

import { NotificationDetail } from './NotificationDetail';

export const Notifications = () => {
  const [alert, setAlert] = useAlert();
  const [eventSelect, setEventSelect] = useState([]);
  const [hasAll, setHasAll] = useState(false);

  const lookup = useStore((state) => state.lookup);
  const providerSelect = getSelect(lookup.Provider, {
    keyField: 'code',
    label: 'code',
    selectAll: { value: '', label: 'All Providers' },
  });
  const merchantSelect = getSelect(lookup.Merchant, {
    keyField: 'code',
    label: 'code',
    selectAll: { value: '', label: 'All Merchants' },
  });

  const crud = useCRUD({
    id: 'notifications',
    title: 'Inbound Notifications',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    filter: {
      merchant: '',
      provider: '',
      actioned: 'false',
      event: '',
    },
    fetch: async (qry: string) => {
      let result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/notification/fetch${qry || ''}`,
      });
      return result.data;
    },
  });
  const { filter, updateFilter, refresh, data } = crud;

  useEffect(() => {
    //ADJUST EVENT SELECTION DYNAMICALLY ACCORDING TO DATA
    if (!hasAll && data.length) {
      let eSelect: any = [{ value: '', label: 'All' }];
      let eTypes: any = {};
      for (let i = 0; i < data.length; i++) {
        let rec: any = data[i];
        let key = _get(eTypes, rec.event, rec.event);
        if (!_get(eTypes, rec.event, false)) {
          eTypes[key] = '';
        }
      }
      let keys = Object.keys(eTypes);
      keys.forEach((key) => {
        if (key) eSelect.push({ label: key ? key : 'All', value: key });
      });
      setEventSelect(eSelect);
      setHasAll(true);
    }
  }, [data, hasAll, setHasAll]);

  useEffect(() => {
    //RESET EVENT SELECTOR TO AUTO SET WHEN PROVIDER CHANGES
    setHasAll(false);
  }, [filter.provider]);

  const postNotification = useCallback(
    async (row: any) => {
      //FIX WEIRD ISSUE WHERE SESSION STATE NOT WORKING (GET DIRECT FROM SESSION STORAGE)
      const extStore = JSON.parse(String(window.sessionStorage.getItem(`crud-store`)));
      await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/provider/${String(row.provider).toLowerCase()}/notification`,
        data: {
          repost: row.id,
        },
      });
      //REFRESH USING STORE FILTER VALUES (AS useCRUD values may have been lost)
      refresh({ filter: { ...extStore.state.notifications } });
    },
    [refresh],
  );

  let columns = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row: any) => row['id'],
        sortable: true,
        width: '80px',
      },
      {
        name: 'Date',
        selector: (row: any) => row['date'],
        format: (rec: any) => moment(rec.date).format('DD/MM/YYYY @hh:mma'),
        sortable: true,
        width: '170px',
      },
      {
        name: 'Provider',
        selector: (row: any) => row['provider'],
        sortable: true,
        width: '100px',
      },
      {
        name: 'Merchant',
        selector: (row: any) => row['merchant_id'],
        sortable: true,
        width: '100px',
      },
      {
        name: 'Provider Ref or File',
        selector: (row: any) => row['provider_ref'],
        sortable: true,
      },

      {
        name: 'Event',
        selector: (row: any) => row['event'],
        sortable: true,
        width: '120px',
      },
      {
        name: 'Reason',
        selector: (row: any) => row['reason'],
        format: (row: any) => right(row.reason, 100, '...'),
        sortable: true,
        wrap: true,
      },
      {
        name: 'Actioned',
        selector: (row: any) => row['actioned'],
        cell: (row: any) => (row.actioned ? <FaCheck color="green" /> : <FaTimes color="red" />),
        sortable: false,
        center: true,
        width: '80px',
      },
      {
        name: 'Fetch',
        selector: (row: any) => row['actioned'],
        cell: (row: any) => (
          <Box
            borderRadius="5px"
            as="button"
            border="0px"
            bg="gray.100"
            _hover={{ bg: 'gray.300' }}
            p={1}
            disabled={row.event !== 'REPORT_AVAILABLE'}
            onClick={() => {
              copyClipboard(row.reason);
              //Download File
              saveAs(row.reason, row.provider_ref);
            }}
          >
            <FaCloudDownloadAlt color="green" />
          </Box>
        ),
        sortable: false,
        center: true,
        width: '65px',
      },
      {
        name: 'Re-Run',
        selector: (row: any) => row['actioned'],
        cell: (row: any) => (
          <Button
            variant="solid"
            //disabled={row.actioned}
            colorScheme={row.actioned ? 'secondary' : 'success'}
            size="xs"
            border="0px"
            onClick={() => {
              postNotification(row);
            }}
          >
            Run
          </Button>
        ),
        sortable: false,
        center: true,
        width: '70px',
      },
    ],
    [postNotification],
  );

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '300px 180px 180px 180px 250px 1fr 150px',
        ]}
      >
        <FilterInputSearch crud={crud} />
        <FilterInputSelect
          placeholder="Provider..."
          defaultValue={filter.provider}
          width="150px"
          onChange={(val: string) => {
            updateFilter({ provider: val });
          }}
          options={providerSelect}
        />
        <FilterInputSelect
          placeholder="Merchant..."
          defaultValue={filter.merchant}
          width="150px"
          onChange={(val: string) => {
            updateFilter({ merchant: val });
          }}
          options={merchantSelect}
        />
        <FilterInputSelect
          placeholder="Select Type..."
          defaultValue={filter.actioned}
          width="150px"
          onChange={(val: string) => {
            updateFilter({ actioned: val });
          }}
          options={[
            {
              label: 'Actioned',
              value: 'true',
            },
            {
              label: 'Un Actioned',
              value: 'false',
            },
            {
              label: 'All',
              value: '~',
            },
          ]}
        />
        <FilterInputSelect
          placeholder="Event..."
          defaultValue={filter.event}
          width="150px"
          onChange={(val: string) => {
            updateFilter({ event: val });
          }}
          options={eventSelect}
        />
        <GridItem />
        <BtnRefresh crud={crud} />
      </Filters>
    );
  }, [crud, filter, updateFilter, providerSelect, eventSelect, merchantSelect]);

  const conditionalRowStyles = [
    {
      when: (row: any) => row.type === 'PAYMENT' && row.net > 0,
      style: {
        color: 'green',
        backgroundColor: '#f3f8f3',
        fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.type === 'PREAUTH' && row.net > 0,
      style: {
        color: 'purple',
        backgroundColor: '#f6f1f6',
        fontWeight: 600,
      },
    },
  ];

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="notifications" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Table
          id="bank_table"
          crud={crud}
          columns={columns}
          responsive
          conditionalRowStyles={conditionalRowStyles}
          expandableRows
          expandableRowsComponent={NotificationDetail}
          striped={false}
        ></Table>
      </CRUD>
    </Box>
  );
};
