import React from 'react';
import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react';

import { TooltipVariants } from 'theme/components';

export interface ITooltipProps extends Omit<ChakraTooltipProps, 'variant'> {
  variant?: keyof TooltipVariants;
}

const Tooltip: React.FC<ITooltipProps> = (props) => {
  const { label, placement, hasArrow, children, ...rest } = props;

  return (
    <ChakraTooltip label={label} placement={placement} hasArrow={hasArrow} {...rest}>
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;
