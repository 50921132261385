import { useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth, useDB } from 'store';
import { Text, Modal } from 'components';

//GET FORMATTED ERROR TO OUTPUT TO KEYBOARD
//const formatObject = (obj) => {
//let text = '';
//let keys = Object.keys(obj);
//for (let i = 0; i < keys.length; i++) {
//let key = keys[i];
//if (typeof obj[key] === 'object') {
//text += formatObject(obj[key]);
//} else {
//text += `${String(key + ':')
//.trim()
//.padEnd(10, ' ')}${obj[key]}\n`;
//}
//}
//return text;
//};

export const DBAlert = () => {
  const navigate = useNavigate();

  const dbAlert = useDB((state) => state.alert);
  const mode = useAuth((state) => state.user?.Mode);

  const onClose = useCallback(() => {
    useDB.getState().clearAlert();
    useAuth.setState({ logout: true });
    navigate('/');
  }, [navigate]);

  const hasExpired = useMemo(
    () => dbAlert?.message === 'Login Session has expired' || dbAlert?.message === 'Unauthorized',
    [dbAlert?.message],
  );

  // AUTO LOGOUT FOR SESSION KEY EXPIRY
  useEffect(() => {
    if (hasExpired) {
      navigate('/');
      useDB.setState({ sessionExpired: true });
      useDB.getState().setToken('');
      useAuth.getState().setUser({});
    }
  }, [hasExpired, navigate]);

  return (
    <Modal
      isOpen={dbAlert && dbAlert?.error}
      onClose={onClose}
      title={dbAlert?.title}
      size={'sm'}
      isCentered={true}
      okProps={{ display: 'none' }}
      cancelText={dbAlert?.status === 403 ? 'Close' : 'Refresh'}
      modalSpace={hasExpired || mode === 'API' ? 0 : '220px'}
    >
      {dbAlert?.message && <Text fontSize={'md'}>{dbAlert?.message}</Text>}
    </Modal>
  );
};
