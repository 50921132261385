import React, { useCallback, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { Text, Divider, Icon } from 'components';

import { useDB } from 'store';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export const options: any = {
  scales: {
    y: {
        ticks: {
            callback: (val: any) => {
                return parseInt(val).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }
        }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const TotalRevenue = () => {
  const [chartData, setChartData] = useState<any>();

  const getData = useCallback(async () => {
    const result = await useDB.getState().axios({
      server: 'PAYGATE',
      method: 'POST',
      url: `/api/v1/analytics/TotalRevenue/`,
    });

    const labels: any[] = [];
    const amounts: any[] = [];
    const amountLabel: any[] = []

    result.data.data.map((v: any) => {
        labels.push(moment.utc(v.Date).format('MMM'))
        amounts.push(v.Revenue)
        amountLabel.push(parseInt(v.Revenue).toLocaleString("en-US",{style:"currency", currency:"USD"}))
        return null
    })

    const dt = {
      labels: labels,
      datasets: [
        {
          label: '',
          data: amounts,
          borderColor: 'green',
          backgroundColor: 'green',
        },
      ],
      detail: {},
    };

    setChartData(dt);
  }, []);

  useEffect(() => {
    getData()
  }, [getData])

  if (!chartData || !Object.keys(chartData).length) {
    return <></>;
  }

  return (
    <Flex
      borderRadius={'4px'}
      border={'1px solid #F2F3F3'}
      bg={'white'}
      p={'24px'}
      flexDir={'column'}
      height={'100%'}
      overflow={'auto'}
    >
      <Flex h={'44px'}>
        <Icon variant={'RevenueChart'} size={11} />
        <Flex flexDir={'column'} ml={'12px'}>
          <Text variant={'heading05Bold'} color={'secondary.800'} mb={'4px'}>
           Total Payment Value for Last 12 Months
          </Text>
          <Spacer />
        </Flex>
      </Flex>
      <Divider my={'16px'} variant={'fade'} />
      <Box fontFamily={'Roboto Condensed'} overflow={'hidden'}>
        <Line options={options} data={chartData} />
      </Box>
    </Flex>
  );
};

export default React.memo(TotalRevenue);