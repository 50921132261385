import { useEffect } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { Form, Input } from 'Library/CRUD';

interface IMerchantEdit {
  id: string;
  crud: any;
}

export const ControlEdit = (props: IMerchantEdit) => {
  const { id, crud } = props;
  const form = useForm();
  const { base_currency } = form.watch();

  const setEditorOnly = async () => {
    if (crud.editorOnly && ((crud && !crud.record) || !Object.keys(crud.record).length)) {
      const rec = await crud.read(crud.key || null);
      form.reset(rec);
    }
  };

  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record);
    } else if (crud && crud.editorOnly) {
      setEditorOnly();
    }

    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record]);

  const onSubmit = async () => {
    // e.preventDefaullt()
    const result = await crud.submit(form.getValues());
    if (result && !result.error) crud.set({ active: false });
  };

  return (
    <Form minHeight={550} form={form} crud={crud} id={id} onSubmit={form.handleSubmit(onSubmit)}>
      <Grid gap="8px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <Input
            form={form}
            name="base_currency"
            label="Base Currency"
            variant="input"
            size="lg"
            value={base_currency}
            placeholder="Enter ISO Base Currency"
          />
        </GridItem>
      </Grid>
    </Form>
  );
};
