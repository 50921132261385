import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';

export interface InputVariants {
  outline: ThemeThunk<SystemStyleObject>;
}

export interface InputSizes {
  xs: ThemeThunk<SystemStyleObject>;
  sm: ThemeThunk<SystemStyleObject>;
  md: ThemeThunk<SystemStyleObject>;
  lg: ThemeThunk<SystemStyleObject>;
}

const parts = ['field', 'leftIcon'];

const baseStyle = {
  field: {
    opacity: 1,
    lineHeight: 1,
    _placeholder: {
      color: 'gray.500',
    },
    _disabled: {
      opacity: 1,
      background: 'gray.400',
      color: 'gray.500',
    },
    _readOnly: {
      opacity: 1,
      bg: 'gray.400',
      color: 'gray.500',
    },
  },
  leftIcon: {
    svg: {
      width: '1em',
      height: '1em',
    },
  },
  rightIcon: {
    svg: {
      width: '1em',
      height: '1em',
    },
  },
};

const variants = {
  outline: (props: any) => {
    const { theme, isSuccess } = props;
    const boxShadowColor = getColor(theme, 'blue.300');

    return {
      field: {
        bg: 'gray.100',
        borderColor: 'gray.500',
        borderRadius: '4px',
        color: 'gray.800',
        fontWeight: '500',

        ...(isSuccess
          ? {
              borderColor: 'green',
            }
          : {}),

        _invalid: {
          borderColor: 'red.500',
          boxShadow: 'none',
        },

        _disabled: {
          opacity: 1,
          background: 'gray.400',
          color: 'gray.700',
        },

        _hover: {
          borderColor: 'blue.500',

          _disabled: {
            borderColor: 'gray.500',
          },
        },

        _focus: {
          borderRadius: '4px',
          borderColor: 'blue.500',

          boxShadow: `0 0 0 1px #fff, 0 0 0 3px ${boxShadowColor}`,

          _invalid: {
            borderColor: 'red.500',
            boxShadow: 'none',
          },
        },
      },
    };
  },
};

const sizes = {
  xs: {
    field: {
      px: '4px',
      height: '22px',
      fontSize: '12px',

      ':not(:last-child)': {
        pr: '22px',
      },
    },
    leftIcon: {
      left: '4px',
      width: '14px',
      height: '22px',

      svg: {
        fontSize: '14px',
      },

      '+ input': {
        pl: '22px',
      },
    },
    rightIcon: {
      right: '4px',
      width: '14px',
      height: '22px',

      svg: {
        fontSize: '14px',
      },
    },
  },
  sm: {
    field: {
      px: '8px',
      height: '32px',
      fontSize: '14px',

      //':not(:last-child)': {
      //pr: '32px',
      //},
    },
    leftIcon: {
      left: '8px',
      width: '16px',
      height: '32px',

      svg: {
        fontSize: '16px',
      },

      '+ input': {
        pl: '32px',
      },
    },
    rightIcon: {
      right: '8px',
      width: '16px',
      height: '32px',

      svg: {
        fontSize: '16px',
      },
    },
  },
  md: {
    field: {
      px: '12px',
      height: '40px',
      fontSize: '14px',
    },
    leftIcon: {
      left: '12px',
      width: '16px',
      height: '40px',

      svg: {
        fontSize: '16px',
      },

      '+ input': {
        pl: '36px',
      },
    },
    rightIcon: {
      right: '12px',
      width: '16px',
      height: '40px',

      svg: {
        fontSize: '16px',
      },
    },
  },
  lg: {
    field: {
      px: '12px',
      height: '48px',
      fontSize: '16px',

      ':not(:last-child)': {
        pr: '44px',
      },
    },
    leftIcon: {
      left: '12px',
      width: '24px',
      height: '48px',

      svg: {
        fontSize: '24px',
      },

      '+ input': {
        pl: '44px',
      },
    },
    rightIcon: {
      right: '12px',
      width: '24px',
      height: '48px',

      svg: {
        fontSize: '24px',
      },
    },
  },
};

const theme = {
  parts,
  baseStyle,
  variants,
  sizes,
};

export default theme;
