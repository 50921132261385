const sidebarTheme = {
  parts: ['menuItemsContainer', 'menuItem', 'avatar', 'avatarRow', 'dropdownButton'],
  baseStyle: {
    menuItemsContainer: {
      bg: 'transparent',
      paddingRight: 0,
      width: '204px', // 220px minus 16px padding
    },
    menuItem: {
      padding: '0 16px',
      bg: 'transparent',

      whiteSpace: 'nowrap',

      fontSize: '14px',
      lineHeight: '16px',
      color: 'white',

      _focus: {
        outline: 0,
        boxShadow: 0,
      },

      _hover: {
        backgroundColor: 'blue.800',
      },

      _active: {
        backgroundColor: 'blue.900',
      },

      _selected: {
        backgroundColor: 'orange.500',

        _hover: {
          backgroundColor: 'orange.500',
        },

        _active: {
          backgroundColor: 'orange.500',
        },
      },

      _before: {
        content: 'none',
      },

      borderEndRadius: 5,
      borderStartRadius: 0,
      justifyContent: 'flex-start',
      height: '44px',
      width: '204px',
    },
    avatarRow: {
      cursor: 'pointer',

      padding: '4px 8px 0px',
      bg: 'transparent',

      transition: 'width 250ms ease-out',
      minWidth: '220px',
      width: '220px',
      marginBottom: '12px',
    },
    avatar: {
      justifyContent: 'center',

      padding: '8px',
      paddingLeft: '5px',

      paddingTop: '16px',
      paddingBottom: '16px',
      width: '100%',

      _hover: {
        bg: 'blue.800',
      },

      _focus: {
        bg: 'blue.900',
      },

      _active: {
        bg: 'blue.900',
      },
    },
    dropdownButton: {
      marginBottom: '8px',
      w: '100%',
      height: '46px',
      bg: 'transparent',
      color: 'white',
      pr: 3,
      pl: 0,
      _before: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'secondary.900',
      },
      _active: {
        bg: 'secondary.1000',
      },
      _focus: {
        bg: 'secondary.1000',
      },
    },
  },
};

export default sidebarTheme;
