import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';

export interface DividerVariants {
  fade: ThemeThunk<SystemStyleObject>;
  normal: ThemeThunk<SystemStyleObject>;
  contrast: ThemeThunk<SystemStyleObject>;
  subtle: ThemeThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemeThunk<SystemStyleObject>>;
interface DividerTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
  variants: Options<keyof DividerVariants>;
}

const dividerTheme: DividerTheme = {
  baseStyle: {
    borderStyle: 'solid',
  },
  variants: {
    fade: {
      borderColor: 'secondary.300',
    },
    normal: {
      borderColor: 'secondary.400',
    },
    subtle: {
      borderColor: 'secondary.500',
    },
    contrast: {
      borderColor: 'secondary.700',
    },
  },
};

export default dividerTheme;
