import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Text, Breadcrumbs } from 'components';

interface IHeaderProps {
  headerText: string | JSX.Element;
  breadcrumbs?: boolean;
  children?: React.ReactNode;
}

const Header: React.FC<IHeaderProps> = (props) => {
  const { headerText, breadcrumbs = true, children, ...rest } = props;

  return (
    <Flex fontFamily={'Public Sans'} mx={3} my={1} flexDirection="column" paddingTop="1" {...rest}>
      {breadcrumbs ? <Breadcrumbs fontSize={12} /> : <></>}
      <Text variant={'heading04Bold'} {...rest}>
        {headerText}
      </Text>
    </Flex>
  );
};

export default Header;
