import { useMemo } from 'react';
import { Box, GridItem } from '@chakra-ui/react';
import { FaFlag, FaCheck, FaTimes } from 'react-icons/fa';
import numeral from 'numeral';

import CRUD, {
  useCRUD,
  Editor,
  Table,
  FilterInputSearch,
  Filters,
  BtnRefresh,
  BtnInactive,
  BtnAddRecord,
  MultiLineHeader,
  InactiveFlag,
} from 'Library/CRUD';
import { useDB } from 'store';
import { Alert } from 'components';
import { useAlert } from 'utils';

import { RateEdit } from './RateEdit';

export const Rates = () => {
  const [alert, setAlert] = useAlert();

  // eslint-disable-next-line
  const crud = useCRUD({
    id: 'rates',
    title: 'Rates',
    hasEditor: true,
    keyField: 'code',
    setAlert: setAlert,
    editor: {
      title: 'Rates',
    },
    fetch: async () => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/rate/fetch`,
      });
      return result.data;
    },
    create: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/rate/alter`,
        data: rec,
      });
      return result;
    },
    read: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/rate/read/${key}`,
      });
      return result;
    },
    update: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/rate/alter`,
        data: rec,
      });
      return result;
    },
    delete: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'DELETE',
        url: `/api/management/rate/delete/${key}`,
      });
      return result;
    },
  });

  let columns = useMemo(
    () => [
      {
        name: <FaFlag />,
        selector: (row: any) => row['inactive'],
        format: (row: any) => <InactiveFlag flag={row.inactive} />,
        width: '25px',
        omit: !crud.inactive,
      },
      {
        name: 'Code',
        selector: (row: any) => row['code'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Rate Plan',
        selector: (row: any) => row['rateplan'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Name',
        selector: (row: any) => row['name'],
        sortable: true,
      },
      {
        name: 'Rate Class',
        selector: (row: any) => row['rateclass'],
        width: '120px',
        sortable: true,
      },
      {
        name: 'Card Type',
        selector: (row: any) => row['apply_to_card_type'],
        width: '80px',
        sortable: false,
        center: true,
      },
      {
        name: <MultiLineHeader heading={['Apply', 'Refund']} align="center" />,
        selector: (row: any) => row['apply_to_refund'],
        cell: (row: any) =>
          row.apply_to_refund ? <FaCheck color="green" /> : <FaTimes color="red" />,
        center: true,
        width: '80px',
        sortable: false,
      },
      {
        name: <MultiLineHeader heading={['Apply', 'Auth']} align="center" />,
        selector: (row: any) => row['apply_to_authorisation'],
        cell: (row: any) =>
          row.apply_to_authorisation ? <FaCheck color="green" /> : <FaTimes color="red" />,
        center: true,
        width: '80px',
        sortable: false,
      },
      {
        name: <MultiLineHeader heading={['Foreign', 'Only']} align="center" />,
        selector: (row: any) => row['apply_to_foreign_only'],
        cell: (row: any) =>
          row.apply_to_foreign_only ? <FaCheck color="green" /> : <FaTimes color="red" />,
        center: true,
        width: '80px',
        sortable: false,
      },
      {
        name: <MultiLineHeader heading={['Premium', 'Only']} align="center" />,
        selector: (row: any) => row['apply_to_premium_only'],
        cell: (row: any) =>
          row.apply_to_premium_only ? <FaCheck color="green" /> : <FaTimes color="red" />,
        center: true,
        width: '80px',
        sortable: false,
      },
      {
        name: 'Basis',
        selector: (row: any) => row['basis'],
        width: '60px',
        center: true,
        sortable: false,
      },
      {
        name: 'Value',
        selector: (row: any) => row['value'],
        format: (row: any) =>
          numeral(row.basis === 'P' ? row.value / 100 : row.value).format(
            row.basis === 'P' ? '%0.00' : '$0,0.00',
          ),
        width: '70px',
        sortable: false,
        right: true,
      },
    ],
    [crud.inactive],
  );

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr', '400px 1fr 150px 150px 150px']}
      >
        <FilterInputSearch crud={crud} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAddRecord crud={crud} />
      </Filters>
    );
    // eslint-disable-next-line
  }, [crud.filter, crud.inactive]);

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="rates" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Editor crud={crud}>
          <RateEdit id="rate-edit" crud={crud} />
        </Editor>
        <Table
          id="rate-table"
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </Box>
  );
};
