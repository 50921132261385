import React from 'react';
import { Text as ChakraText, TextProps as ChakraTextProps } from '@chakra-ui/react';

import { TextVariants } from 'theme/components';
import { PseudoPropNames } from 'types';

export type FontSizeVariant = keyof TextVariants;
export interface TextProps extends Omit<ChakraTextProps, PseudoPropNames> {
  variant?: FontSizeVariant;
}

const Text: React.FC<TextProps> = (props) => {
  const { children, variant = 'standard', ...rest } = props;
  return (
    <ChakraText textStyle="body" {...rest} variant={variant} fontFamily={'Public Sans'}>
      {children}
    </ChakraText>
  );
};

export default Text;
