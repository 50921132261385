import { useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { Button } from 'components';

export const BtnDelete = (props) => {
  const { crud, onClick, label, ...o } = props;
  const [confirmDelete, setConfirmDelete] = useState(false);

  const processDeletion = () => {
    setConfirmDelete(false);
    onClick();
  };

  if (!confirmDelete) {
    return (
      <Button
        variant="solid"
        colorScheme="red"
        w="150px"
        onClick={() => setConfirmDelete(true)}
        {...o}
      >
        {label || 'Delete'}
      </Button>
    );
  }

  return (
    <Flex direction="row">
      <Button
        m={0}
        colorScheme="default"
        color="gray.800"
        variant="transaprent"
        disabled={true}
        {...o}
      >
        CONFIRM DELETION
      </Button>

      <Button m={0} ml={1} onClick={processDeletion} colorScheme="green">
        YES
      </Button>

      <Button m={0} ml={1} onClick={() => setConfirmDelete(false)} colorScheme="red">
        NO
      </Button>
    </Flex>
  );
};
