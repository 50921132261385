import React, { useEffect, useMemo } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { Box, Flex, Text, Button, Grid, GridItem, Link } from '@chakra-ui/react';
import _get from 'lodash/get';
import { useDownloadPDF } from 'utils';

import CRUD, { useCRUD, Table, MultiLineHeader, NumberFormat } from 'Library/CRUD';
import { useAuth } from 'store';
import { Alert, Modal } from 'components';
import { useAlert } from 'utils';
import ReportsPDFModal from 'routes/Reports/ReportsPDFModal';

interface I_TransactionDrilldown {
  data: any;
  parent: any;
  refreshParent: any;
  applyFees: (id: string) => {};
}

const TransactionDrilldown = (props: I_TransactionDrilldown) => {
  let { data, parent, refreshParent, applyFees, ...o } = props;
  const user = useAuth((state) => state.user);

  const {downloadPdf, showNoPdf, isOpen, fileUrl, fileName, onClose, setShowNoPdf} = useDownloadPDF();

  const [alert, setAlert] = useAlert();

  //FACILITATE CHILD REFRESH FROM PARENT
  let childRefresh = _get(props, 'filter._childRefresh', null);
  useEffect(() => {
    if (crud && crud.refresh) crud.refresh({ message: 'Tran Drilldown Child Refresh' });
    // eslint-disable-next-line
  }, [childRefresh]);

  const crud = useCRUD({
    id: 'transactiondrilldown',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    drilldown: true,
    filter: {
      startDate: moment().subtract(2, 'days').toDate(),
      endDate: new Date(),
      //type: 'PAYMENT',
    },
    fetch: async () => {
      return data.Details;
    },
  });

  let columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: (row: any) => row['Date'],
        format: (rec: any) => moment(rec.Date).format('DD/MM/YYYY'),
        width: '75px',
        sortable: true,
      },

      {
        name: 'Reference',
        selector: (row: any) => row['ProviderReference'],
        format: (row: any) => {
          if (data?.Source === 'terminal') return <Link color='teal.500' onClick={() => downloadPdf(row['ProviderReference'])} variant='link'>{row['ProviderReference']}</Link>;
          return <>{row['ProviderReference']}</>;
        },
        sortable: true,
        width: '130px',
      },
      {
        name: 'Type',
        selector: (row: any) => row['TransactionType'],
        sortable: true,
        width: '70px',
      },
      {
        name: 'Status',
        selector: (row: any) => row['Status'],
        format: (row: any) => (
          <Text
            fontWeight={row.Status === 'ERROR' ? 'bold' : 'normal'}
            color={row.Status === 'RECORDED' ? 'red' : row.Status === 'VERIFIED' ? 'green' : ''}
          >
            {row.Status}
          </Text>
        ),
        width: '75px',
        sortable: true,
      },

      {
        name: parent ? 'Notice' : 'Reason / Notice',
        selector: (row: any) => row.Notice,
        sortable: true,
      },
      {
        name: 'Batch / Settlement ID',
        selector: (row: any) => row['SettlementId'],
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row: any) => row['Amount'],
        sortable: false,
        format: (row: any) => (
          <NumberFormat
            amount={row.Amount}
            color={row.TransactionType === 'REFUND' ? 'red' : 'green'}
          />
        ),
        width: '85px',
        right: true,
      },
      //{
      //name: <MultiLineHeader heading={['Settled', 'Fees']} align="right" />,
      //selector: (row: any) => row['SettledAmount'],
      //sortable: false,
      //format: (row: any) => numeral(row.Amount - row.SettledAmount).format('0,0.00'),
      //width: '85px',
      //right: true,
      //},
      {
        name: <MultiLineHeader heading={['Expected', 'Fees']} align="right" />,
        selector: (row: any) => row['ExpectedProcessFee'],
        sortable: false,
        format: (row: any) => (
          <Text
            color={row.ExpectedProcessFee <= row.SettledAmount ? 'green' : 'red'}
            fontWeight={row.ExpectedProcessFee > row.SettledAmount ? 'bold' : 'normal'}
          >
            {numeral(row.ExpectedProcessFee).format('0,0.00')}
          </Text>
        ),
        width: '85px',
        right: true,
        omit: user.Mode === 'API' || user.Type !== 'ADMIN',
      },
      //{
      //name: 'Net',
      //selector: (row: any) => row['Amount'],
      //sortable: false,
      //format: (row: any) => (
      //<NumberFormat
      //amount={row.SettledAmount}
      //color={row.Status === 'REFUND' ? 'red' : 'green'}
      ///>
      //),
      //right: true,
      //width: '85px',
      //},
    ],
    [parent, user, data, downloadPdf],
  );

  const conditionalRowStyles = [
    {
      when: (row: any) =>
        row.type === 'PAYMENT' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'green',
        //fontWeight: 600,
      },
    },
    {
      when: (row: any) =>
        row.type === 'PREAUTH' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'blue',
        //fontWeight: 600,
      },
    },
    {
      when: (row: any) =>
        row.type === 'REFUND' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'darkorange',
        //fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.status === 'ERROR',
      style: {
        color: 'red',
        //backgroundColor: '#faedf0',
        //textDecoration: 'line-through',
        // /fontWeight: 600,
      },
    },
  ];

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="transactiondetail" crud={crud} {...o}>
        <Alert alert={alert} setAlert={setAlert} />
        <Grid templateColumns="1fr 150px">
          <GridItem>
            <Flex mt={2} direction="row" fontSize={14}>
              <Text mr={1}>Transaction ID:</Text>
              <Text color="blue">{data.id}</Text>
            </Flex>
          </GridItem>
          <GridItem p={1} alignSelf={'center'}>
            {user.Type === 'ADMIN' && (
              <Button
                w="100%"
                size="sm"
                colorScheme="red"
                variant="outline"
                onClick={() => {
                  applyFees(data.id);
                }}
              >
                RE-CALC FEES
              </Button>
            )}
          </GridItem>
        </Grid>
        <Table
          id="transaction_drilldown"
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
          conditionalRowStyles={conditionalRowStyles}
          //expandableRows
          //expandableRowsComponent={TransactionFees}
        />
        {/* <Button
        onClick={() => {
          refreshParent()
        }}
      >
        Refresh Parent
      </Button> */}
      </CRUD>
      {isOpen &&
        <ReportsPDFModal
          fileUrl={fileUrl}
          fileName={fileName}
          isOpen={isOpen}
          onClose={onClose}
          title='Terminal Receipt'
        />
      }
      {
        showNoPdf && <Modal 
        isOpen={showNoPdf}
        onClose={() => setShowNoPdf(false)}
        children={<div>There is no receipt data available for this transaction</div>}
        cancelText={'Close'}
        okProps={{ display: 'none' }}
        size={'sm'}
        />
      }
    </Box>
  );
};

export default React.memo(TransactionDrilldown);
