// Note: This is JSX component (not Typescript) as use of jsoneditor-react had issues using Types
import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputGroup,
  GridItem,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { useForm } from 'react-hook-form';

import { Form, InputControl, Select } from 'Library/CRUD';
import { getSelect } from 'Library';
import { useStore } from 'store';

export const TemplateEdit = (props) => {
  const { id, crud } = props;
  const form = useForm();
  const { provider, tran_type, status_on_success } = form.watch();

  const [req, setReq] = React.useState({});
  const [newResp, setNewResp] = React.useState({});
  const [newReq, setNewReq] = React.useState({});
  const [providerSelect, setProviderSelect] = useState([]);
  const [typeSelect, setTypeSelect] = React.useState([]);
  const [statusSelect, setStatusSelect] = React.useState([]);

  // APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      setReq(crud.record.request_template);
      form.reset(crud.record);
    }

    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record]);

  const lookup = useStore((state) => state.lookup);

  useEffect(() => {
    setProviderSelect(
      getSelect(lookup.Provider, {
        keyField: 'id',
        label: 'name',
        //orderBy: ['label', 'desc'],
      }),
    );
    setTypeSelect(
      getSelect(lookup.TranType, {
        keyField: 'code',
        label: 'name',
      }),
    );
    setStatusSelect(
      getSelect(lookup.Status, {
        keyField: 'code',
        label: 'name',
      }),
    );
  }, [lookup]);

  const onSubmit = async () => {
    const obj = { ...form.getValues() };
    if (Object.keys(newResp).length > 0) obj.response_mapping = JSON.parse(newResp);
    if (Object.keys(newReq).length > 0) obj.request_template = JSON.parse(newReq);
    const result = await crud.submit(obj);
    if (result && !result.error) crud.set({ active: false });
  };

  return (
    <Form form={form} crud={crud} id={id} onSubmit={form.handleSubmit(onSubmit)}>
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Request Template</Tab>
          <Tab>Response Mapping</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid gap="8px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
              <GridItem>
                <InputControl
                  id="provider"
                  form={form}
                  label="Provider"
                  validation={{
                    required: 'Provider is Required',
                  }}
                  control={
                    <InputGroup size="lg">
                      <Select
                        name="provider"
                        defaultValue={provider}
                        options={providerSelect}
                        onChange={(val) => {
                          //crud.setRecord({ provider: val })
                          form.setValue('provider', val);
                        }}
                        size="lg"
                        placeholder="Select Provider..."
                      />
                    </InputGroup>
                  }
                />
              </GridItem>
              <GridItem />
              <GridItem>
                <InputControl
                  id="tran_type"
                  form={form}
                  label="Transaction Type"
                  validation={{
                    required: 'Transaction Type is Required',
                  }}
                  control={
                    <InputGroup size="lg">
                      <Select
                        name="tran_type"
                        defaultValue={tran_type}
                        options={typeSelect}
                        onChange={(val) => {
                          //crud.setRecord({ tran_type: val })
                          form.setValue('tran_type', val);
                        }}
                        size="lg"
                        placeholder="Select Provider..."
                      />
                    </InputGroup>
                  }
                />
              </GridItem>
              <GridItem />
              <GridItem>
                <InputControl
                  id="status_on_success"
                  form={form}
                  label="Status on Success"
                  validation={{
                    required: 'Status on Success is Required',
                  }}
                  control={
                    <InputGroup size="lg">
                      <Select
                        name="status_on_success"
                        defaultValue={status_on_success}
                        options={statusSelect}
                        onChange={(val) => {
                          //crud.setRecord({ tran_type: val })
                          form.setValue('status_on_success', val);
                        }}
                        size="lg"
                        placeholder="Select Status on Success..."
                      />
                    </InputGroup>
                  }
                />
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            <JSONInput
              id="json_request"
              width="100%"
              placeholder={req}
              locale={locale}
              onKeyPressUpdate={false} //Stop cursor returning to top of JSON
              onBlur={(val) => {
                setNewReq(val.json);
              }}
              //reset={true}
              height="500px"
            />
          </TabPanel>
          <TabPanel>
            <JSONInput
              id="json_response"
              width="100%"
              placeholder={form.getValues('response_mapping')}
              //reset={true}
              locale={locale}
              height="500px"
              onKeyPressUpdate={false} //Stop cursor returning to top of JSON
              onBlur={(val) => {
                setNewResp(val.json);
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Form>
  );
};
