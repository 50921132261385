import React, { useMemo, useState, useEffect } from 'react';
import { Flex, Box, Spinner } from '@chakra-ui/react';

import Routes, { Login, Embedded } from 'routes';
import { Sidebar, DBAlert } from 'components';
import { useLoadData, useHandleAuth, isMobile as checkIfIsMobile } from 'utils';
import { useDB } from 'store';

import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

const App = () => {
  const isMobile = useMemo(() => checkIfIsMobile(), []);
  const viewPortHeight = useMemo(
    () => (isMobile ? `${window.innerHeight}px` : `100vh`),
    [isMobile],
  );

  const [isApiMode, setApiMode] = useState(window.location.search.indexOf('?api') >= 0);

  useEffect(() => {
    if (window.location.search.indexOf('?api') >= 0) {
      setApiMode(true);
    }
  }, []);

  const authToken = useHandleAuth();
  useLoadData(); // fetches all data to be stored on page load, only starts if authenticated

  return (
    <>
      <DBSpinner />
      <DBAlert />
      {isApiMode ? (
        <Embedded />
      ) : (
        <Flex w={'100vw'} h={viewPortHeight}>
          {authToken ? (
            <>
              <Sidebar />
              <Box w={'100%'} h={'100%'} overflow={'auto'} bg={'gray.100'} p={1}>
                <Routes />
              </Box>
            </>
          ) : (
            <Login />
          )}
        </Flex>
      )}
    </>
  );
};

/* useDB store is universally used for all API calls. it has a `loading` boolean
 * which is used to conditionally show a spinner to indicate that a query is processing
 * */
const DBSpinner = React.memo(() => {
  const isLoading = useDB((state) => state.loading);

  return (
    <>
      {isLoading && (
        <Spinner
          position="absolute"
          sx={{ top: '50%', left: '50%' }}
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
          zIndex={99999999}
        />
      )}
    </>
  );
});

export default App;
