import { Flex } from '@chakra-ui/react';
import { useLocation, Link } from 'react-router-dom';

import { Icon, Text } from 'components';

interface BreadcrumbsProps {
  fontSize: number;
}
export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { fontSize } = props;

  const location = useLocation();
  const path = location.pathname || '';
  const paths = path.split('/');

  const getTo = (index: number) => {
    let toStr = '';
    for (let j = 1; j <= index; j++) {
      toStr += '/' + paths[j];
    }
    return toStr;
  };

  const ele = [];
  for (let i = 1; i < paths.length; i++) {
    ele.push(
      <Link key={`bc_${i}`} to={getTo(i)}>
        <Text fontSize={fontSize} mr="1" color="gray.700" className={`tp-breadcrumb`}>
          {`/${paths[i]}`}
        </Text>
      </Link>,
    );
  }

  return (
    <Flex direction="row" alignItems={'center'}>
      <Icon variant="AccountsManagement" mr="1" />
      {ele}
    </Flex>
  );
};
