import React, { useRef } from 'react';
import { FormLabel, FormControl, FormHelperText, FormErrorMessage } from '@chakra-ui/react';
import _get from 'lodash/get';

interface InputContainerProps {
  form: any;
  id: string;
  control: any;
  label?: string;
  children?: React.ReactNode;
  outerProps?: any;
  labelProps?: any;
  labelContainerProps?: any;
  formProps?: any;
  className?: string;
  helperText?: any;
  validation?: any;
  isError?: boolean;
  required?: boolean;
}

export const InputControl: React.FC<InputContainerProps> = ({
  id,
  form,
  control,
  children,
  label,
  outerProps,
  className = '',
  labelProps,
  labelContainerProps,
  formProps,
  isError,
  required,
  helperText,
  validation,
  ...o
}) => {
  let ref = useRef();
  let {
    register,
    formState: { errors },
  } = form;

  let error = _get(form.formState.errors, id, null);
  return (
    <FormControl isInvalid={_get(errors, id, false)} {...outerProps}>
      <FormLabel as="legend" className="pg-label">
        {label}
      </FormLabel>
      {React.cloneElement(control, {
        id: id,
        name: id,
        className: 'gp-input',
        ref: ref,
        ...register(id, { ...validation }),
        ...o,
      })}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormErrorMessage>{_get(errors, `${id}.message`, '?????')}</FormErrorMessage>}
    </FormControl>
  );
};
