import { useEffect, useCallback } from 'react';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';

import { useAuth, useStore, useDB, ILookup } from 'store';

export const useLoadData = () => {
  const merchant = useAuth((state) => state.user?.Merchant);
  const access = useAuth((state) => state.user?.AllowViewPaymentSummary);
  const dbToken = useDB((state) => state.token);

  // Load Lookup & Control Values
  const loadLookups = useCallback(async () => {
    const result = await useDB.getState().axios({
      server: 'PAYGATE',
      method: 'GET',
      url: `/api/v1/control/lookup`,
    });

    // Place Lookups in ZuStand Session Storage
    const keys = Object.keys(_get(result, 'data.data', {}));
    keys.forEach((key) => {
      useStore
        .getState()
        .setLookup(
          key as unknown as keyof ILookup,
          _keyBy(_get(result, `data.data.${key}`, []), 'Code') as any,
        );
    });
  }, []);

  useEffect(() => {
    if (dbToken && access) {
      loadLookups();
    }
  }, [merchant, loadLookups, dbToken, access]);
};
