import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';

export interface FormLabelSizes {
  sm: ThemeThunk<SystemStyleObject>;
  md: ThemeThunk<SystemStyleObject>;
  lg: ThemeThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemeThunk<SystemStyleObject>>;
interface FormLabelTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
  sizes: Options<keyof FormLabelSizes>;
}

const formLabelTheme: FormLabelTheme = {
  baseStyle: (props) => {
    const { mb = '5px' } = props;

    return {
      marginBottom: mb,
      marginInlineEnd: '4px',
      color: 'gray.800',
      fontWeight: 600,
      _disabled: {
        color: 'gray.500',
        opacity: 1,
      },
    };
  },
  sizes: {
    sm: {
      fontSize: '14px',
      lineHeight: '16px',
    },
    md: {
      fontSize: '14px',
      lineHeight: '16px',
    },
    lg: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
};

export default formLabelTheme;
