import React from 'react';
import { forwardRef, MenuItemOptionProps, Box, useMenuOption, useStyles } from '@chakra-ui/react';
import { Icon } from 'components';

interface Props extends MenuItemOptionProps {}

const DropdownItemOption = forwardRef<Props, React.FC>(({ icon, ...props }, ref) => {
  const styles = useStyles();
  const itemProps = useMenuOption(props, ref);
  const isChecked = props.isChecked;

  return (
    <Box {...itemProps} as="button" type="button" sx={styles.item}>
      <Box as="span" display="flex" alignItems="center" mr="8px">
        {icon && <Box mr="8px">{icon}</Box>}
        {itemProps.children}
      </Box>
      <Icon
        variant={isChecked ? 'ToggleOn' : 'ToggleOff'}
        style={{
          transition: 'all 0.2s',
          color: isChecked ? '#118061' : '#94989B',
          fontSize: '24px',
        }}
      />
    </Box>
  );
});

// this is required because chakra MenuOptionGroup checks the id before
// cloning the children and passsing the props.
DropdownItemOption.id = 'MenuItemOption';

export default DropdownItemOption;
