const menuTheme = {
  baseStyle: {
    list: {
      py: 0,
      border: 0,
      outline: 0,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08), 0 0 0 4px #E6E7E8',
      borderRadius: '4px',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      w: '100%',
      px: '8px',
      py: '0',
      fontWeight: 'medium',
      cursor: 'pointer',

      _first: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      _last: {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },

      // set dimension using em to enable fontSize
      svg: {
        width: '1em',
        height: '1em',
        color: 'gray.700',
        fontSize: '16px',
      },

      _selected: {
        bg: '#E3F1F8',
      },

      _focus: {
        bg: '#E3F1F8',
        outline: 'none',
      },

      _active: {
        bg: '#E3F1F8',
      },
    },
  },
  sizes: {
    sm: {
      item: {
        fontSize: '14px',
        lineHeight: '16px',
        height: '32px',
      },
    },
    md: {
      item: {
        fontSize: '16px',
        height: '40px',
      },
    },
  },
};
export default menuTheme;
