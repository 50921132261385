import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Spacer,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

const MsgBox = (props) => {
  let {
    hook,
    title,
    message,
    yes = {},
    no = {},
    cancel = {},
    footer,
    children,
    headerProps,
    footerProps,
    bodyProps,
  } = props;

  if (headerProps) headerProps.borderTopRadius = '5px';

  if (!hook) console.error('hook prop must be passed to MsgBox');
  let yesProps = { ...hook.yes, ...yes };
  let noProps = { ...hook.no, ...no };
  let cancelProps = { ...hook.cancel, ...cancel };
  let foot = { ...hook.footer, ...footer };

  let buttons = [];
  if (yesProps && Object.keys(yesProps).length)
    buttons.push(
      <Button
        key="yesBtn"
        id="msgBoxYes"
        mx={1}
        colorScheme={yesProps.colorScheme || 'success'}
        onClick={() => {
          if (yesProps.onClick) yesProps.onClick(hook.value, hook);
          onClose();
        }}
        {...yesProps.props}
      >
        {yesProps.Label || 'YES'}
      </Button>,
    );
  if (noProps && Object.keys(noProps).length)
    buttons.push(
      <Button
        key="noBtn"
        id="msgBoxNo"
        mx={1}
        colorScheme={noProps.colorScheme || 'destructive'}
        onClick={() => {
          if (noProps.onClick) noProps.onClick(hook.value, hook);
          onClose();
        }}
        {...noProps.props}
      >
        {noProps.Label || 'NO'}
      </Button>,
    );
  if (cancelProps && Object.keys(cancelProps).length)
    buttons.push(
      <Button
        key="cancelBtn"
        id="msgBoxCancel"
        mx={1}
        onClick={() => {
          if (cancelProps.onClick) cancelProps.onClick(hook.value, hook);
          onClose();
        }}
        {...cancelProps.props}
      >
        {cancelProps.Label || 'CANCEL'}
      </Button>,
    );

  const onClose = () => hook.close();
  const cancelRef = React.useRef();
  useEffect(() => {
    if (hook.timeout && hook.isOpen) {
      setTimeout(
        () => {
          hook.close();
        },
        hook.timeout,
        hook.isOpen,
      );
    }
    // eslint-disable-next-line
  }, [hook.timeout, hook.isOpen]);

  return (
    <>
      <AlertDialog isOpen={hook.isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" {...headerProps}>
              {hook.title || title}
            </AlertDialogHeader>
            <AlertDialogBody {...bodyProps}>
              {hook.message || message}
              {children}
            </AlertDialogBody>
            <AlertDialogFooter {...footerProps}>
              <Flex w="100%">
                {foot}
                <Spacer />
                {buttons}
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

MsgBox.PropsTypes = {
  hook: PropTypes.isRequired,
};

export default MsgBox;
