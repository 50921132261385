import React, { useEffect, useState } from 'react';
import { Select as ChakraSelect, SelectProps, forwardRef } from '@chakra-ui/react';

import { Icon } from 'components';

export type SelectSizes = 'sm' | 'md' | 'lg';

export interface ISelectProps extends SelectProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  // This will make the default option to be gray.800 if true
  hasValue?: boolean;
  disabledColorText?: string;
}

const iconSizes = {
  xs: '14px',
  sm: '16px',
  md: '16px',
  lg: '24px',
};

const Select: React.FC<ISelectProps> = forwardRef((props, ref) => {
  const {
    size = 'md',
    placeholder,
    onChange,
    children,
    hasValue: hasValueProps,
    disabledColorText,
    ...rest
  } = props;
  const iconSize = iconSizes[size];
  const SelectIcon = <Icon variant={'CaretDown'} />;
  const [hasValue, setHasValue] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(e);
    setHasValue(!!e?.target?.value);
  };

  useEffect(() => {
    if (((props.value || props.defaultValue) && !hasValue) || hasValueProps) {
      setHasValue(true);
    } else if (!hasValueProps && !props.value && !props.defaultValue && hasValue) {
      setHasValue(false);
    }
  }, [props.value, props.defaultValue, hasValue, hasValueProps]);

  return (
    <ChakraSelect
      aria-selected={hasValue}
      icon={SelectIcon}
      iconSize={iconSize}
      size={size}
      ref={ref}
      onChange={handleChange}
      {...rest}
    >
      {!placeholder ? (
        <option value="" hidden></option>
      ) : (
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
      )}
      {children}
    </ChakraSelect>
  );
});

export default Select;
