import React from 'react';
import { FaEye } from 'react-icons/fa';

import { Button } from 'components';

const BtnInactive = (props) => {
  const { crud, toggle, filter, color, active, label, children, ...otherProps } = props;

  return (
    <Button
      variant={crud.inactive ? 'solid' : 'outline'}
      colorScheme={crud.inactive ? 'dark' : 'blackAlpha'}
      m={0}
      size="md"
      leftIcon={<FaEye color={crud.inactive ? 'gray' : 'black'} />}
      onClick={() => {
        crud.toggleInactive();
      }}
      disabled={crud && crud.readonly}
      {...otherProps}
    >
      {label || 'Inactive'}
    </Button>
  );
};

export default React.memo(BtnInactive);
