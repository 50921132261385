import React, { useState, useEffect } from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import { Box, Flex, Text, Spacer } from '@chakra-ui/react';
import RDT from 'react-data-table-component';
import PropTypes from 'prop-types';

import classes from './CRUDTable.styles.module.css';
import { DefaultTableStyle } from '.';

const Table = (props) => {
  const { id, crud, columns, rowHeight, children, customStyle, customFilter, maxH, ...o } = props;
  const rowHeightCalc = rowHeight || '28px';

  const [style] = useState(Object.assign(DefaultTableStyle, props.mergeCustomStyles));

  useEffect(() => {
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, []);

  const rowClicked = (row) => {
    //21/7/20 Added ability to set row._disabled property to prevent editor
    if (row && row._editordisabled && row._editordisabled === true) return;
    //if (row && row._readonly) return
    if (crud.hasEditor) {
      if (row && !row[crud.keyField])
        console.error(`KeyField [${crud.keyField}] does not exist in row - Check keyField Value`);
      crud.set({
        key: row[crud.keyField],
        active: true,
      });
    }
  };
  const [tableData, setTableData] = useState(crud?.data);

  useEffect(() => {
    const filters = customFilter ? Object.keys(customFilter).map((k) => customFilter[k]) : [];
    const hasFilter = filters.reduce((a, b) => (!!b ? true : a), false);
    if (!hasFilter || !crud?.data) {
      setTableData(crud?.data);
    } else {
      const newMap = crud?.data.map(item => {
        item.disabled = item.Details === null || item.Details === undefined;
        return item;
      });
      const arr = filters.filter((v) => !!v).reduce((a, b) => b(a), newMap);
      setTableData(arr);
    }
  }, [customFilter, crud?.data]);

  //Display No data if data is zero length
  if (!crud.drilldown && !tableData.length)
    return (
      <Flex direction="column" h="100%">
        <Spacer />
        <Text textAlign={'center'} fontSize="22px">
          No data to display ....
        </Text>
        <Spacer />
      </Flex>
    );

  if (!id) throw new Error('ID attribute is required on Table');

  //USE FULL WIDTH FLEX CONTAINER SO THAT CHILDREN FILE REQUIRED HEIGHT & TABLE FILLS REMAINDER
  //CHILDREN (TYPICALLY INFO TEXT) ARE NOT SHOW FOR MD DEVICES DOWN

  return (
    <Box
      id={id}
      h="100%"
      w="100%"
      //className="no-scroll-bar"
      // Scroll is allowed in Either direction but scrollbar is hidden
      overflow={'auto'}
      {...(maxH && { maxH })}
    >
      {crud.tableHeading}
      <Box h="100%" w="100%" id="RDT-BOX" className={classes.CRUDTableWrapper}>
        <RDT
          //To hide older checkboxes and get correctly styled boxes change this class name
          style={{
            border: '1px solid lightgray',
            borderRadius: '3px',
          }}
          id={id}
          editKey={crud.keyField}
          keyField={crud.keyField || 'id'} //Added 7/11/2021 Added to fix bug where Drilldowns remain active
          className="standard-checkbox"
          data={tableData}
          columns={columns}
          onRowClicked={rowClicked}
          highlightOnHover
          striped
          responsive
          customStyles={customStyle ? { ...style, ...customStyle } : style}
          rowHeight={rowHeightCalc}
          pagination={!crud || !crud?.drilldown}
          noHeader={tableData && tableData.length <= 0}
          noDataComponent={crud.drilldown ? <></> : <FaEllipsisH className="mt-1" />}
          dense
          {...o}
          title={
            crud.drilldown ? (
              <Text py={1} sx={{ fontWeight: 600 }} fontSize="md">
                {crud.title}
              </Text>
            ) : (
              ''
            )
          }
        />
      </Box>
    </Box>
  );
};

Table.PropsTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(Table);
