import { ThemeComponentProps } from '@chakra-ui/theme';
import { getColor, transparentize } from '@chakra-ui/theme-tools';

const parts = ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'];

const baseStyle = (props: ThemeComponentProps) => {
  const { theme } = props;
  const overlayBg = transparentize(getColor(theme, 'gray.1000'), 0.5)(theme);

  return {
    overlay: {
      bg: overlayBg,
    },
    dialogContainer: {
      padding: '32px',
    },
    dialog: {
      overflow: 'hidden',
      borderRadius: '4px',
      boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.32)',
      // we target the custom scroll here to avoid conflict with
      // children that uses custom scroll like Table
      '.Modal-ScrollbarsCustom-Content': {
        display: 'flex !important',
        flexDirection: 'column !important',
      },
      '.Modal-ScrollbarsCustom-TrackX': {
        display: 'none !important',
      },
      '.Modal-ScrollbarsCustom-TrackY': {
        top: '0 !important',
        right: '7px !important',
        width: '8px !important',
        height: 'calc(100% - 16px) !important',
        bg: 'transparent !important',

        '> .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY': {
          background: '#E6E7E8 !important',
          borderRadius: '100px !important',
        },

        // offset position scroll when has tabs
        '&--hasTabs': {
          top: '0px !important',
          padding: '64px 0 16px !important',
          height: '100% !important',
        },
      },
    },
    header: {
      flexShrink: 0,
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '24px',
      zIndex: 9999, // temp, header not always on top
    },
    closeButton: {
      pos: 'initial',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '20px',
      padding: 0,

      '.chakra-tabs': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,

        '&__tablist-container': {
          flexShrink: 0,
        },

        '&__tab-panels': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        },
        // remove horizontal and bottom padding for tab
        '&__tab-panel': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: '16px 0 0',
        },
      },
    },
    footer: {
      flexShrink: 0,
      marginTop: 'auto',
      bg: 'blue.100',
      borderTop: 'solid 1px',
      borderTopColor: 'gray.400',
    },
  };
};

const sizes = {
  sm: {
    dialog: {
      width: '100%',
      maxWidth: '448px',
      maxHeight: '100%',
    },
    header: {
      padding: '12px 16px',
    },
    body: {
      padding: '0 22px 16px',
    },
    footer: {
      padding: '8px 16px',
    },
  },
  md: {
    dialog: {
      width: '85%',
      height: '85%',
      maxWidth: '820px',
    },
    header: {
      padding: '12px 24px',
    },
    body: {
      padding: '0 24px 16px',
    },
    footer: {
      padding: '8px 24px',
    },
  },
  lg: {
    dialog: {
      width: '100%',
      height: '100%',
      maxWidth: '1080px',
    },
    header: {
      padding: '12px 24px',
    },
    body: {
      padding: '0 24px 16px',
    },
    footer: {
      padding: '8px 24px',
    },
  },
};

export const modalTheme = {
  parts,
  baseStyle,
  sizes,
};
