import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDB } from 'store';

export const useDownloadPDF = () => {

    const db = useDB();

    const [showNoPdf, setShowNoPdf] = useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [fileUrl, setFileUrl] = useState<any>(null);
    const [fileName, setFileName] = useState<string>('');


    const downloadPdf = useCallback(

        async (tranRef: string) => {

            const result = await db.axios(
                {
                    server: 'PAYGATE',
                    method: 'GET',
                    url: `/api/v1/receipt?reference=${tranRef}`,
                    responseType: 'arraybuffer',
                    data: {
                        'Format': 'pdf',
                        'Delivery': 'download',
                        'DeliveryTo': ''
                    }
                }
            )
            if (!result?.error) {

                // This is a check to ensure there is actually Pdf data. A byteLenth of a api call with no pdf data has a length of 748. So we check for anything below 1000
                if (result?.data.byteLength < 1000) {
                    setShowNoPdf(true)
                    return;
                }

                const blob = new Blob([result.data], { type: 'application/pdf' });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                setFileUrl(link.href);
                link.download = tranRef + ".pdf";
                setFileName(link.download);
                onOpen();
            }

            return;
        }

        , [db, onOpen])

    return {downloadPdf, showNoPdf, setShowNoPdf, isOpen, onOpen, onClose, fileUrl, fileName};
}
