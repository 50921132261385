import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { AspectRatio, Flex, Link, useDisclosure } from '@chakra-ui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import _get from 'lodash/get';

import { Modal, Text, FormControl, Input, Card, Button, Icon } from 'components';
import { usePasswordType } from 'utils';
import { loginBG, GPPayLogoLogin } from 'assets';
import { useDB, useAuth, EnumMode, EnumUserType, IUser } from 'store';

import classes from './Login.styles.module.css';

interface LoginProps {}
interface ILogin {
  gp_serial_no: string;
  username: string;
  password: string;
}

interface LoginButtonProps {
  form: UseFormReturn<ILogin>;
  isLoading: boolean;
  loadingText: string;
}
const LoginButton: React.FC<LoginButtonProps> = React.memo((props) => {
  const {
    form: { control },
    isLoading,
    loadingText,
  } = props;

  // should check for serial number?
  //const serialNumber = useWatch({ control, name: 'gp_serial_no' });

  const username = useWatch({ control, name: 'username' });
  const password = useWatch({ control, name: 'password' });

  const [autoFilled, setAutoFilled] = useState(false);

  useLayoutEffect(() => {
    const checkAutofilled = () => {
      const usernameField = document.querySelector('input[name="username"]');
      const pwField = document.querySelector('input[name="password"]');
      setAutoFilled(
        !!usernameField?.matches('*:-webkit-autofill') || !!pwField?.matches('*:-webkit-autofill'),
      );
    };
    // The time when it's ready is not very stable, so check few times
    setTimeout(checkAutofilled, 500);
    setTimeout(checkAutofilled, 1000);
    setTimeout(checkAutofilled, 2000);
  }, []);

  return (
    <Button
      isDisabled={autoFilled ? false : !username || !password}
      loadingText={loadingText}
      isLoading={isLoading}
      colorScheme="accent"
      label={'Continue'}
      variant="solid"
      width="100%"
      type="submit"
    />
  );
});

const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mutateAsync = async (payload: ILogin) => {
    try {
      setIsLoading(true);
      const result = await useDB.getState().axios(
        {
          method: 'POST',
          url: '/login',
          data: {
            Username: payload.username,
            Password: payload.password,
            SerialNumber: payload.gp_serial_no,
          },
        },
        false,
      );
      return result;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutateAsync };
};

export const Login: React.FC<LoginProps> = () => {
  const { passwordType, togglePasswordType } = usePasswordType();
  const { mutateAsync, isLoading } = useLogin();

  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const serial = searchParams.get('serial');

  const navigate = useNavigate();

  const form = useForm<ILogin>({
    defaultValues: {
      gp_serial_no: '',
      username: '',
      password: '',
    },
  });
  const { handleSubmit, register, setFocus, setValue, reset } = form;

  useEffect(() => {
    setFocus('gp_serial_no');
    const checkSerialNum = localStorage.getItem('serialNumber') !== '';
    const getSerialNum = checkSerialNum
      ? localStorage.getItem('serialNumber')?.toString() || ''
      : '';
    setValue('gp_serial_no', getSerialNum);
  }, [setFocus, setValue]);

  useEffect(() => {
    const obj = {
      username: '',
      gp_serial_no: '',
      password: '',
    };
    if (username) {
      obj.username = username;
    }
    if (serial) {
      obj.gp_serial_no = serial;
    }
    if (serial || username) {
      reset(obj);
    }
  }, [username, serial, reset]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorText, setErrorText] = useState('');

  const onSubmit = useCallback(
    async (data: ILogin) => {
      const result = await mutateAsync(data);
      if (result && !result.error) {
        localStorage.setItem('serialNumber', data.gp_serial_no);
        const obj: IUser = _get(result, 'data.data', {
          id: '',
          DisplayName: '',
          token: '',
          Type: EnumUserType.MERCHANT,
          email: '',
          mode: EnumMode.LOGIN,
          Merchant: '', //Will only be blank if ADMIN
        });

        if (
          !obj.AllowViewPaymentSummary &&
          !obj.AllowViewPaymentSettlements &&
          !obj.AllowViewPaymentTransactions
        ) {
          setErrorText('You do not have the rights to access GuestPoint Pay portal.');
          onOpen();
          return;
        }

        navigate('/');
        useAuth.getState().setUser(obj);
        return;
      }

      if (result.error) {
        setErrorText(result.message);
        onOpen();
      }
    },
    [mutateAsync, onOpen, navigate],
  );

  return (
    <>
      {isOpen && (
        <Modal
          size={'sm'}
          isOpen={isOpen}
          onClose={onClose}
          okProps={{ display: 'none' }}
          cancelText={'Close'}
          modalSpace={0}
          title={'Login Failed'}
        >
          {errorText}
        </Modal>
      )}
      <div className={classes.LoginImageContainer}>
        <Flex
          w={'40vw'}
          h={'100vh'}
          overflow={'hidden'}
          backgroundColor={'#FFF'}
          background={`url(${loginBG})`}
          backgroundSize="cover"
        />
      </div>
      <Flex
        h={'100vh'}
        w={'60vw'}
        overflow={'hidden'}
        backgroundColor={'#FFF'}
        className={classes.LoginCentre}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <AspectRatio w={'286.5px'} h={'48px'} mb={'32px'}>
              <img src={GPPayLogoLogin} alt={'GuestPoint Pay Logo'} />
            </AspectRatio>
            <Card
              size="sm"
              width="453px"
              height="382px"
              filter={
                'drop-shadow(-2px -2px 8px rgba(0, 0, 0, 0.08)) drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.08))'
              }
              boxShadow={'none'}
              p={'32px'}
            >
              <Text variant="heading05Bold" mb={4}>
                {'Sign In To Your Account'}
              </Text>
              <Flex direction={'column'}>
                <FormControl size="md" label={'Serial Number'} mb={4}>
                  <Input {...register('gp_serial_no')} />
                </FormControl>
                <FormControl size="md" label={'Username'} mb={4}>
                  <Input {...register('username')} />
                </FormControl>
                <FormControl size="md" label={'Password'} mb={'18px'}>
                  <Input
                    rightElement={
                      <Icon
                        color="gray.700"
                        size={8}
                        variant="ViewInput"
                        onClick={togglePasswordType}
                        cursor={'pointer'}
                      />
                    }
                    type={passwordType}
                    {...register('password')}
                  />
                </FormControl>
                <LoginButton form={form} isLoading={isLoading} loadingText={'Logging in'} />
              </Flex>
            </Card>
            <div style={{ lineHeight: '40px' }}>
              <Link
                href="https://www.guestpoint.com"
                target="_blank"
                variant="labelExtraSmallSemiBold"
                style={{ display: 'inline-block' }}
                paddingRight="8px"
              >
                <Text variant="labelExtraSmallSemiBold" mr="1" color="gray.800">
                  © GuestPoint
                </Text>
              </Link>
              <Text
                style={{ display: 'inline-block' }}
                variant="labelExtraSmallSemiBold"
                mr="1"
                color="gray.800"
              >
                &bull;
              </Text>
              <Link
                href="https://www.guestpoint.com/contact-us/"
                target="_blank"
                variant="labelExtraSmallSemiBold"
                style={{ display: 'inline-block' }}
                paddingLeft="8px"
                paddingRight="8px"
              >
                <Text variant="labelExtraSmallSemiBold" mr="1" color="gray.800">
                  Contact Support
                </Text>
              </Link>
              <Text
                style={{ display: 'inline-block' }}
                variant="labelExtraSmallSemiBold"
                mr="1"
                color="gray.700"
              >
                &bull;
              </Text>
              <Link
                href="https://www.guestpoint.com/terms"
                target="_blank"
                variant="labelExtraSmallSemiBold"
                style={{ display: 'inline-block' }}
                paddingLeft="8px"
              >
                <Text variant="labelExtraSmallSemiBold" mr="1" color="gray.800">
                  Terms of use
                </Text>
              </Link>
            </div>
          </div>
        </form>
      </Flex>
    </>
  );
};
