import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { Box } from '@chakra-ui/react';

import { EnumMode, useAuth, useDB } from 'store';
import Routes from 'routes';

export const Embedded = () => {
  const dbToken = useDB((state) => state.token);
  const mode = useAuth((state) => state.user?.Mode);
  const sessionExpired = useDB((state) => state.sessionExpired);

  // Use Local Storage for Heights as it will be lost if db.token is lost (eg: For demo app)
  const [height, setHeight] = useState(localStorage.getItem('paygate-client-window-height') || 500);

  const receiveMessage = useCallback((event: MessageEvent) => {
    if (event && event.data) {
      const obj = event.data;
      const { session = false, permissions = {}, variant = '', h = 0 } = obj;

      if (h) setHeight(h);
      if (variant) useAuth?.getState?.().setVariant(variant);
      if (session) {
        if (session.token) {
          const userObj = {
            id: session.merchant,
            token: session.token,
            Name: session.merchant,
            Mode: EnumMode.API,
            Merchant: session.merchant,
          };
          useAuth?.getState?.().setUser({ ...userObj, ...permissions });
          useDB.getState().setToken(session.token);
        } else {
          useAuth?.getState?.().setUser({
            id: '',
            token: '',
            Name: '',
            Mode: EnumMode.API,
            AllowViewPaymentSummary: false,
            AllowViewPaymentTransactions: false,
            AllowViewPaymentSettlements: false,
          });
          useDB.getState().setToken('');
        }
      }
    }
  }, []);

  useLayoutEffect(() => {
    window.parent.postMessage({ message: 'READY', ready: true }, '*');
  }, []);

  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  useEffect(() => {
    localStorage.setItem('paygate-client-window-height', String(height));
  }, [height]);

  useEffect(() => {
    if (sessionExpired) {
      window.parent.postMessage({ sessionExpired: true }, '*');
    }
  }, [sessionExpired]);

  if (!dbToken || mode !== EnumMode.API) {
    return <>{'not authenticated'}</>;
  }

  return (
    <Box pr={1} w="100%" height={height}>
      <Routes />
    </Box>
  );
};
