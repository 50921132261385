import React from 'react';
import { Box, Text } from '@chakra-ui/react';
export const MultiLineHeader = (props) => {
  let { heading, align, ...o } = props;
  return (
    <Box w="100%">
      {heading.map((rec, i) => {
        return (
          <Text key={i} {...o} textAlign={align || 'center'}>
            {rec}
          </Text>
        );
      })}
    </Box>
  );
};
