import React, { useState } from 'react';

export const useMsgBox = (opts) => {
  let options = {
    active: false,
    title: '',
    message: '',
    actions: [],
    timeout: 0,
    yes: {
      label: 'YES',
      colorScheme: 'green',
      props: {},
    },
    no: {
      label: 'NO',
      props: {},
    },
    cancel: null,
    ...opts,
    footer: <></>,
  };
  const open = (vals) => {
    setState((prevState) => {
      return { ...prevState, ...vals, isOpen: true };
    });
  };
  const close = () =>
    setState((prevState) => {
      return { ...prevState, isOpen: false };
    });
  const setMsg = (obj) =>
    setState((prevState) => {
      return { ...prevState, obj };
    });
  const setTimeout = (tm) =>
    setState((prevState) => {
      return { ...prevState, timeout: tm };
    });

  const [state, setState] = useState(options);
  //const [value, setVal] = useState()
  return { ...state, open, close, setMsg, setTimeout };
};
