import React, { useEffect, useState, useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDinersClub,
  FaCreditCard,
} from 'react-icons/fa';

import CRUD, { useCRUD, Table, NumberFormat } from 'Library/CRUD';

const CardType = (props: { card: string }) => {
  const { card } = props;
  switch (card) {
    case 'VISA':
      return <FaCcVisa size="18px" />;
    case 'MCARD':
      return <FaCcMastercard size="18px" />;
    case 'AMEX':
      return <FaCcAmex size="18px" />;
    case 'DINER':
      return <FaCcDinersClub size="18px" />;
    default:
      return <FaCreditCard size="18px" />;
  }
};

interface IReportSettlementDrilldown{
  data: any;
  dataHandle: string;
  type: string;
}

const ReportsSettlementDrilldown = (props: IReportSettlementDrilldown) => {
  let { data, dataHandle, type, ...o } = props;

  const [tableData, setTableData] = useState(data[dataHandle]);
  const [refresh, setRefresh] = useState(false);

  const dataString = JSON.stringify(data[dataHandle]);

  const crud = useCRUD({
    id: 'reportsSettlementDrilldown',
    title: "",
    hasEditor: false,
    keyField: 'id',
    drilldown: true,
    fetch: async () => {
      if (type === "transactions") {
        return data[dataHandle].slice(0, -1);
      }
      return data[dataHandle];
    },
  });


  if (refresh) {
    if (type === "transactions") {
      setTableData(data[dataHandle].slice(0, -1));
    } else {
      setTableData(data[dataHandle]);
    }
    setRefresh(false);
  }

  useEffect(() => {
    setRefresh(true);
  }, [dataString])

  let columnsTransactions = useMemo(
    () => [
      {
        name: 'Date',
        selector: (row: any) => row['Date'],
        width: '200px',
        sortable: true,
      },

      {
        name: 'Type',
        selector: (row: any) => row['TransactionType'],
        sortable: true,
        width: '150px',
      },
      {
        name: 'Reference',
        selector: (row: any) => row['Reference'],
        sortable: true,
        width: '150px',
      },
      {
        name: 'Note',
        selector: (row: any) => row['Reason'],
        sortable: true,
      },
      {
        name: 'Card',
        selector: (row: any) => row['CardType'],
        format: (row: any) => <CardType card={row.CardType} />,
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        name: 'Credit',
        selector: (row: any) => row['Credit'],
        sortable: true,
        format: (row: any) => <NumberFormat color={'green'} amount={row.Credit} />,
        width: '120px',
        right: true,
      },
      {
        name: 'Debit',
        selector: (row: any) => row['Debit'],
        sortable: true,
        format: (row: any) => <NumberFormat color={'red'} amount={row.Debit} />,
        width: '120px',
        right: true,
      },
      {
        name: 'Fee',
        selector: (row: any) => row['Fee'],
        sortable: true,
        format: (row: any) => <NumberFormat color={'red'} amount={row.Fee} />,
        width: '100px',
        right: true,
      },
      {
        name: 'Balance',
        selector: (row: any) => row['Balance'],
        sortable: true,
        format: (row: any) => <NumberFormat color={row.TransactionType === 'REFUND' ? 'red' : 'green'} amount={row.Balance} />,
        width: '120px',
        right: true,
      },
    ],
    [],
  );

  let columnsNotesCombined = useMemo(
    () => [
      {
        name: 'NOTE Settlement details for Debit total:',
        selector: (row: any) => row['Date'],
        sortable: true,
        format: (row: any) => <Text>{"- Refund Transaction Settled on " + row.Date + " (Reference: " + row.Reference + ")"}</Text>,
      },
      {
        name: 'Credit',
        selector: (row: any) => "",
        width: '120px',
        right: true,
      },
      {
        name: 'Debit',
        selector: (row: any) => row["RefundAmount"],
        format: (row: any) => <NumberFormat color={'red'} amount={row.RefundAmount} />,
        width: '120px',
        right: true,
      },
      {
        name: 'Fee',
        selector: (row: any) => "",
        width: '100px',
        right: true,
      },
      {
        name: 'Balance',
        selector: (row: any) => "",
        width: '120px',
        right: true,
      },
    ],
    [],
  );

  let columnsNotesSettlement = useMemo(
    () => [
      {
        name: 'NOTE Debit total is made up of:',
        selector: (row: any) => row['Date'],
        sortable: true,
        format: (row: any) => <Text>{"- Refund Transaction from " + row.Date + " (Reference: " + row.ProviderReference + ")"}</Text>,
      },
      {
        name: 'Credit',
        selector: (row: any) => "",
        width: '120px',
        right: true,
      },
      {
        name: 'Debit',
        selector: (row: any) => row["RefundAmount"],
        format: (row: any) => <NumberFormat color={'red'} amount={row.Amount} />,
        width: '120px',
        right: true,
      },
      {
        name: 'Fee',
        selector: (row: any) => "",
        width: '100px',
        right: true,
      },
      {
        name: 'Balance',
        selector: (row: any) => "",
        width: '120px',
        right: true,
      },
    ],
    [],
  );

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="reportsSettlementDrilldown" crud={crud} {...o}>
        {!refresh &&
        <Table
          id="reportsSettlementDrilldownTable"
          crud={crud}
          data={tableData}
          columns={type === "transactions" ? columnsTransactions : 
            dataHandle === "Settlements" ? columnsNotesCombined : columnsNotesSettlement
          }
          responsive
          pagination={!(crud?.data?.length <= 10)}
          paginationContext
        />
        }
      </CRUD>
    </Box>
  );
};

export default React.memo(ReportsSettlementDrilldown);