import React, { useEffect, useState } from 'react';
import { Grid, InputGroup, GridItem, Checkbox } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { Form, InputControl, Input, Select } from 'Library/CRUD';
import { getSelect } from 'Library';
import { useStore } from 'store';

interface IMerchantEdit {
  id: string;
  crud: any;
}

export const ScheduleEdit = React.memo((props: IMerchantEdit) => {
  const { id, crud } = props;
  const form = useForm();
  const { provider, task, days, hour, minute, inactive } = form.watch();

  const [providerSelect, setProviderSelect] = useState([]);
  const lookup = useStore((state) => state.lookup);

  useEffect(() => {
    setProviderSelect(
      getSelect(lookup.Provider, {
        keyField: 'id',
        label: 'name',
        //orderBy: ['label', 'desc'],
      }),
    );
  }, [lookup]);

  //APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record);
    }
    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record]);

  const taskSelect = [
    { value: 'SETTLEMENT', label: 'Settlement' },
    { value: 'GETPAYMENT', label: 'Get Payment' },
  ];

  const onSubmit = async () => {
    // e.preventDefaullt()
    const result = await crud.submit(form.getValues());
    if (result && !result.error) crud.set({ active: false });
  };

  return (
    <Form minHeight={550} form={form} crud={crud} id={id} onSubmit={form.handleSubmit(onSubmit)}>
      <Grid gap="8px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <InputControl
            id="provider"
            form={form}
            label="Provider"
            validation={{
              required: 'Provider is Required',
            }}
            control={
              <InputGroup size="lg">
                <Select
                  name="provider"
                  defaultValue={provider}
                  options={providerSelect}
                  onChange={(val: any) => {
                    //crud.setRecord({ provider: val })
                    form.setValue('provider', val);
                  }}
                  size="lg"
                  placeholder="Select Provider..."
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem>
          <InputControl
            id="task"
            form={form}
            label="Task"
            validation={{
              required: 'Task is required',
            }}
            control={
              <InputGroup size="lg">
                <Select
                  name="task"
                  defaultValue={task}
                  options={taskSelect}
                  onChange={(val: any) => {
                    form.setValue('task', val);
                  }}
                  size="lg"
                  placeholder="Select Task..."
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Grid gap="8px" templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}>
            <GridItem>
              <Input
                form={form}
                name="days"
                label="Days (0-Sun,6-Sat,*-Every Day)"
                variant="input"
                size="lg"
                value={days}
                placeholder="Eg: 135 = Mon,Wed,Fri"
                defaultValue="*"
              />
            </GridItem>
            <GridItem>
              <Input
                form={form}
                name="hour"
                label="Hour (0-23)"
                variant="input"
                size="lg"
                value={hour}
                placeholder="Enter Hour"
                defaultValue="*"
              />
            </GridItem>
            <GridItem>
              <Input
                form={form}
                name="minute"
                label="Minute (0-59)"
                variant="input"
                size="lg"
                value={minute}
                placeholder="Enter Minute"
                defaultValue="*"
              />
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem colSpan={2}>
          <Checkbox
            isChecked={inactive}
            size="lg"
            onChange={(e) => {
              form.setValue('inactive', e.target.checked);
            }}
          >
            Set as Inactive
          </Checkbox>
        </GridItem>
      </Grid>
    </Form>
  );
});
