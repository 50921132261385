import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Box, Flex } from '@chakra-ui/react';
import _get from 'lodash/get';
import { orderBy } from 'lodash';

import { useStore, useAuth, useDB } from 'store';
import { MsgBox, useMsgBox } from 'Library';
import { useReportViewer, ReportViewer } from 'Library/ReportViewer';
import CRUD, {
  useCRUD,
  Table,
  FilterInputSearch,
  FilterInputDateRange,
  FilterInputSelect,
  Filters,
  BtnRefresh,
  BtnCustom,
  NumberFormat,
} from 'Library/CRUD';
import { Alert } from 'components';
import { useAlert } from 'utils';

import SettlementsDrilldown from './SettlementsDrilldown';

const Settlements = () => {
  const db = useDB();
  const user = useAuth((state) => state.user);
  const multiMerchant = user.Type === 'ADMIN' ? true : false;

  const [alert, setAlert] = useAlert();

  const lookup = useStore((state) => state.lookup);

  const propertyID = Object.keys(lookup.Merchant)?.[0];
  const timezone = lookup.Merchant?.[propertyID as any]?.Timezone;

  const merchantSelect = [{value: '', label: 'All Merchants'}].concat(
    Object.keys(lookup.Merchant).sort().map((m: any) => {
      return(
        {
          value: lookup.Merchant[m].Code,
          label: lookup.Merchant[m].Code + " - " + lookup.Merchant[m].Name
        }
      )
    })
  )

  const report = useReportViewer({ title: 'Settlement Report' });

  const crud = useCRUD({
    id: 'settlement',
    title: user.Mode === 'API' ? '' : 'Settlements',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    waitRefresh: true,
    filter: {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      type: '',
      merchant: '',
    },
    fetch: async (qry: string) => {
      // if (!filter.query) return [] //Wait until there is a filter
      const result = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/settlements${qry || ''}`,
      });

      if (result.data.data === null) {
        return []
      }

      const arr = result.data.data.map((v: any) => ({
        ...v,
        Date: timezone
          ? moment.utc(v.Date).tz(timezone).format('DD/MM/YYYY')
          : moment(v.Date).format('DD/MM/YYYY'),
        NumberOfTransactions: v.Summary.BalancePerCardType.reduce(
          (acc: number, cur: any) => acc + cur.NumberOfTransactions || 0,
          0,
        ),
        SortDate: timezone
          ? new Date(moment.utc(v.Date).tz(timezone).format('MM/DD/YYYY'))
          : new Date(moment(v.Date).format('MM/DD/YYYY')),
      }));

      return orderBy(arr, ['SortDate'], ['desc']);
    },
  });
  const { updateFilter, refresh } = crud;

  //FILTER ON CHANGE OF USER MERCHANT CODE (IF LOGIN USER CHANGES)
  useEffect(() => {
    updateFilter({ merchant: user.Merchant });
  }, [updateFilter, user.Merchant]);

  //REFRESH CRUD IF THERE IS A CHANGE IN DB TOKEN (CAN HAPPEN WITH EMBEDDED CLIENT)
  useEffect(() => {
    refresh();
  }, [refresh, db.token]);

  const updateSettlements = async (crud: any) => {
    let startDate = moment(_get(crud, 'filter.startDate', new Date())).format('YYYY-MM-DD');
    let endDate = moment(_get(crud, 'filter.endDate', new Date())).format('YYYY-MM-DD');

    await db.axios({
      server: 'PAYGATE',
      method: 'POST',
      url: `/api/v1/management/settlement/update`,
      data: {
        merchant_id: crud.filter.merchant,
        from: startDate,
        to: endDate,
      },
    });
    //updateFilter({ merchant: user.merchant, _refresh: ulid() })
    setTimeout(() => crud.refresh(), 1000);
  };

  const msgBox = useMsgBox();

  // const viewReport = React.useCallback(
  //   async (id: string) => {
  //     let result = await db.axios({
  //       server: 'PAYGATE',
  //       method: 'GET',
  //       url: `/api/v1/report/settlement/${id}`,
  //     });
  //     report.set({ data: result.data.data, active: true });
  //   },
  //   [db, report],
  // );

  let columns = useMemo(
    () => [
      // {
      //   name: 'ID',
      //   selector: (row: any) => row['id'],
      //   sortable: true,
      // },
      {
        name: 'Date',
        selector: (row: any) => row['Date'],
        width: '85px',
        sortable: true,
      },
      {
        name: 'Merchant',
        selector: (row: any) => row['Merchant'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Settlement ID',
        selector: (row: any) => row['ProviderReference'],
        sortable: true,
        wrap: true,
      },
      {
        name: 'Number of Transactions',
        selector: (row: any) => row['NumberOfTransactions'] || 0,
        wrap: true,
        sortable: true,
        width: '200px',
      },
      {
        name: 'Payments',
        selector: (row: any) => row['CreditAmount'],
        sortable: false,
        format: (row: any) => <NumberFormat color={'green'} amount={row.CreditAmount} />,
        width: '100px',
        right: true,
      },
      {
        name: 'Refunds',
        selector: (row: any) => row['DebitAmount'],
        sortable: false,
        format: (row: any) => <NumberFormat color={'red'} amount={row.DebitAmount} />,
        width: '100px',
        right: true,
      },
      {
        name: 'Fees',
        selector: (row: any) => row['Summary.TotalFees'],
        sortable: false,
        format: (row: any) => <NumberFormat color={'red'} amount={row.Summary?.TotalFees || 0} />,
        width: '100px',
        right: true,
      },
      {
        name: 'Settlement Total',
        selector: (row: any) => row['BalanceAmount'],
        sortable: false,
        format: (row: any) => <NumberFormat color={'green'} amount={row.BalanceAmount} />,
        width: '140px',
        right: true,
      },
      //{
      //name: <MultiLineHeader heading={['Settled', 'Net']} align="right" />,
      //selector: (row: any) => row['settle_net'],
      //sortable: false,
      //format: (row: any) => numeral(row.settle_net).format('0,0.00'),
      //width: '100px',
      //right: true,
      //},
      //{
      //name: 'View / Print',
      //cell: (row: any) => (
      //<Button
      //variant="outline"
      //size="xs"
      //colorScheme="green"
      //leftIcon={<FaEye color="green" />}
      //onClick={() => {
      //viewReport(row.ProviderReference);
      //}}
      //>
      //View / Print
      //</Button>
      //),
      //sortable: false,
      //center: true,
      //width: '110px',
      //},
    ],
    [],
  );

  const [customFilter, setCustomFilter] = useState<{
    date: null | ((arg: any[] | null) => void);
  }>({
    date: (arr: any[] | null) => {
      if (!arr) return arr;
      return arr.filter((v) =>
        moment(v.Date, 'DD/MM/YYYY HH:mm').isBetween(
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
          'days',
          '[]',
        ),
      );
    },
  });

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          `300px 260px ${multiMerchant ? '200px' : ''} ${multiMerchant ? '170px' : ''} 100px`,
        ]}
      >
        <FilterInputSearch crud={crud} />
        <FilterInputDateRange
          value={{
            from: moment(crud.filter.startDate).toDate(),
            to: moment(crud.filter.endDate).toDate(),
          }}
          onChange={(dates: any) => {
            updateFilter({
              startDate: dates[0],
              endDate: dates[1],
            });
            setCustomFilter((prev) => ({
              ...prev,
              date: (arr: any[] | null) => {
                if (!arr) return arr;
                return arr.filter((v) =>
                  moment(v.Date, 'DD/MM/YYYY HH:mm').isBetween(
                    moment(dates[0]).toDate(),
                    moment(dates[1]).toDate(),
                    'days',
                    '[]',
                  ),
                );
              },
            }));
          }}
        />
        {multiMerchant && (
          <FilterInputSelect
            placeholder="Merchant..."
            defaultValue={crud.filter.merchant}
            width={'100%'}
            onChange={(val: string) => {
              updateFilter({ merchant: val });
            }}
            options={merchantSelect}
          />
        )}
            {multiMerchant && (
              <Flex p={'4px'} justifyContent={'flex-end'}>
                <BtnCustom
                  label="Update Settlements"
                  colorScheme="red"
                  width="100%"
                  disabled={user.Type === 'ADMIN' ? !crud.filter.merchant : false}
                  onClick={() => {
                    msgBox.open({
                      title: 'Update Settlements (Administrators Only)',
                      message: `If you proceed all Settlements in the selected date range from ${moment(
                        crud.filter.startDate,
                      ).format('DD/MM/YYYY')} and ${moment(crud.filter.endDate).format(
                        'DD/MM/YYYY',
                      )} will be re-posted for this merchant`,
                      yes: {
                        onClick: () => {
                          updateSettlements(crud);
                        },
                      },
                    });
                  }}
                />
              </Flex>
            )}
          <Box p={'4px'}>
            <BtnRefresh crud={crud} cascade={true} />
          </Box>
      </Filters>
    );
    //eslint override - exclude function
    // eslint-disable-next-line
  }, [crud, updateFilter, merchantSelect, multiMerchant]);

  const conditionalRowStyles = [
    {
      when: (row: any) => row.type === 'PAYMENT' && row.settled > 0,
      style: {
        color: 'green',
        backgroundColor: '#f3f8f3',
        fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.type === 'PREAUTH' && row.net > 0,
      style: {
        color: 'purple',
        backgroundColor: '#f6f1f6',
        fontWeight: 600,
      },
    },
  ];

  return (
    <Box fontFamily={'Public Sans'} h="100%" lineHeight={1.2}>
      <MsgBox hook={msgBox} headerProps={{ bg: 'red.100' }} />
      <CRUD id="transactions" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <ReportViewer report={report} />
        <Table
          id="bank_table"
          crud={crud}
          columns={columns}
          responsive
          customFilter={customFilter}
          expandableRows
          expandableRowDisabled={(row: any) => row.count <= 1}
          expandableRowsComponent={SettlementsDrilldown}
          expandableRowsComponentProps={{
            pl: '45px',
            parent: 'SETTLEMENT',
            refreshParent: crud.refresh, //Allows Refresh of this parent to be called by child
          }}
          conditionalRowStyles={conditionalRowStyles}
          striped={false}
        ></Table>
      </CRUD>
    </Box>
  );
};

export default React.memo(Settlements);
