import React from 'react';
import {
  useMultiStyleConfig,
  forwardRef,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  HStack,
  VStack,
  InputGroupProps,
  InputElementProps,
  StackProps,
} from '@chakra-ui/react';

import { InputVariants, InputSizes } from 'theme/components';
import { Icon, IconVariant, Text } from 'components';

export interface IInput extends Omit<InputProps, 'variant'> {
  variant?: keyof InputVariants;
  size?: keyof InputSizes;
  leftIcon?: IconVariant;
  rightIcon?: IconVariant;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  isSuccess?: boolean;
  label?: string;
  isRequired?: boolean;
  inputGroupProps?: InputGroupProps;
  leftElementProps?: InputElementProps;
  rightElementProps?: InputElementProps;
  block?: boolean;
  containerProps?: StackProps;
}

const Input = forwardRef<IInput, 'input'>((props, ref) => {
  const {
    size,
    leftIcon,
    rightIcon,
    leftElement,
    rightElement,
    label,
    isRequired = false,
    inputGroupProps,
    block,
    leftElementProps,
    rightElementProps,
    containerProps,
    ...rest
  } = props;
  const styles = useMultiStyleConfig('Input', { size });
  return (
    <VStack align="flex-start" {...containerProps}>
      {label && (
        <HStack pb={2}>
          <Text variant={'labelSmallSemiBold'}>{label}</Text>
          {isRequired && (
            <Text variant={'labelSmallSemiBold'} color={'red.500'}>
              *
            </Text>
          )}
        </HStack>
      )}
      <InputGroup {...inputGroupProps}>
        {(leftIcon || leftElement) && (
          <InputLeftElement pointerEvents="none" __css={styles.leftIcon} {...leftElementProps}>
            {leftIcon ? <Icon color="gray.700" variant={leftIcon} /> : leftElement}
          </InputLeftElement>
        )}
        <ChakraInput ref={ref} size={size} {...rest} />
        {(rightIcon || rightElement) && (
          <InputRightElement sx={styles.rightIcon} {...rightElementProps}>
            {rightIcon ? <Icon color="gray.700" variant={rightIcon} /> : rightElement}
          </InputRightElement>
        )}
      </InputGroup>
    </VStack>
  );
});

export default Input;
