// Note: This is JSX component (not Typescript) as use of jsoneditor-react had issues using Types
import { useState, useEffect, useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';

import { useDB } from 'store';

import 'jsoneditor-react/es/editor.min.css';
import 'brace/mode/json';
import 'brace/theme/github';

export const OutboundDetail = (props) => {
  const { data } = props;
  const [json, setJson] = useState({});

  const getData = useCallback(async () => {
    const details = await useDB.getState().axios({
      server: 'PAYGATE',
      method: 'GET',
      url: `/api/management/outbound/fetchdetail/${data.id}`,
    });
    setJson(details);
  }, [data.id]);

  useEffect(() => {
    getData();
  }, [data.id, getData]);

  if (json && !Object.keys(json).length) return <></>;

  return (
    <Box>
      <Editor
        value={json}
        ace={ace}
        theme="ace/theme/github"
        mode="code"
        statusBar={true}
        allowedModes={['tree', 'code', 'text', 'form']}
      />
    </Box>
  );
};
