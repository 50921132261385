import React, { useState, useCallback } from 'react';

import { FormControl } from 'components';
import "react-datepicker/dist/react-datepicker.css";
import DatePickerCustom from 'components/DatePicker/DatePicker';

interface FilterDateProps{
  value: Date;
  onChange: (newDate: Date) => void;
  maxDate?: Date | undefined;
  minDate?: Date | undefined;
}
export const FilterInputDateSelect: React.FC<FilterDateProps> = (props) => {
  const { onChange: onChangeProp, value, maxDate, minDate} = props;
  const [date, setDate] = useState<Date>(value);

  const onChange = useCallback(
    (newDate: Date) => {
      setDate(newDate);
      onChangeProp(newDate || new Date());
    },
    [onChangeProp],
  );

  return (
    <FormControl p={'4px'}>
        <DatePickerCustom
            value={date}  
            onChange={onChange}
            maxDate={maxDate}
            minDate={minDate}
            buttonProps={{ size: 'md', width: '100%' }}
        />
    </FormControl>
  );
};