import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';

export interface AvatarVariants {
  square: ThemeThunk<SystemStyleObject>;
  round: ThemeThunk<SystemStyleObject>;
}

export interface AvatarSizes {
  sm: ThemeThunk<SystemStyleObject>;
  md: ThemeThunk<SystemStyleObject>;
  lg: ThemeThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemeThunk<SystemStyleObject>>;
interface AvatarTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
  variants: Options<keyof AvatarVariants>;
  sizes: Options<keyof AvatarSizes>;
}

const avatarTheme: AvatarTheme = {
  baseStyle: {
    label: {
      color: 'white',
    },
  },
  variants: {
    square: {
      container: {
        borderRadius: '4px',
      },
    },
    round: {
      container: {
        borderRadius: 'full',
      },
    },
  },
  sizes: {
    sm: {
      container: {
        width: '16px',
        height: '16px',
      },
      label: {
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    md: {
      container: {
        width: '24px',
        height: '24px',
      },
      label: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
    lg: {
      container: {
        width: '32px',
        height: '32px',
      },
      label: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },
};

export default avatarTheme;
