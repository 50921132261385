import numeral from 'numeral';
import { Text } from '@chakra-ui/react';

export interface INumberFormat {
  amount: number;
  format?: string;
  color?: 'red' | 'green';
}

export const NumberFormat = (props: INumberFormat) => {
  let { amount, format, color } = props;
  if (!format) format = '(0,0.00)';
  return (
    <Text color={color ?? (amount < 0 ? 'red' : 'green')}>{numeral(amount).format(format)}</Text>
  );
};
