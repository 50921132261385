import { Box } from '@chakra-ui/react';
import { ReportsSettlement } from './ReportsSettlement';

export const Reports = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const user = useAuth((state) => state.user);

  // let parts = location.pathname.split('/');

  // let activeTab;
  // switch (parts[2]) {
  //   case 'setup':
  //     activeTab = 1;
  //     break;
  //   case 'logging':
  //     activeTab = 2;
  //     break;
  //   default:
  //     activeTab = 0;
  // }
  // let subItem = parts && parts[1] && parts[1] === 'management' && parts[3];

  //IF THERE ARE SUB ITEMS THEN RETURN VIA OUTLET OTHERWISE RETURN SUB MENU
  // if (subItem) return <Outlet />;
  // else
    return (
      <Box>
        <ReportsSettlement />
        {/* <Header headerText="Reports" breadcrumbs={user.Mode !== 'API'} />
        <Tabs defaultIndex={activeTab}>
          <TabList>
            <Tab>Settlements</Tab>
            <Tab>Management</Tab>
            <Tab>Merchant</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ReportsSettlement />
            </TabPanel>
            <TabPanel>
              <Box p={3}>
                <Grid templateColumns="1fr 1fr 1fr" gap="22px">
                  <TaskCard
                    index={0}
                    title="Revenue Report"
                    description="Monthly Revenue Report - to be defined"
                    pinned={false}
                    onClick={() => {
                      navigate('/reports/');
                    }}
                  />
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={3}>
                <Grid templateColumns="1fr 1fr 1fr" gap="22px">
                  <TaskCard
                    index={0}
                    title="Merchants Report"
                    description="To be defined"
                    pinned={false}
                    onClick={() => {
                      navigate('/management/setup/merchants');
                    }}
                  />
                </Grid>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs> */}
      </Box>
    );
};
