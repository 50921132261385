import React, { useState } from 'react';
import { Box, Grid, Flex, GridItem } from '@chakra-ui/react';

import { Header } from 'Library';
import { EnumMode, useAuth } from 'store';
import { Text, Divider, Icon } from 'components';

import { PendingSettlement, RevenueChart, MerchantNotifications } from '.';

interface IMerchantDashboardProps {
  setTabIndex?: (idx: number) => void;
  options?: any;
  setOptions: (obj: any) => void;
}

const MerchantDashboard = (props: IMerchantDashboardProps) => {
  const { setTabIndex, setOptions } = props;
  const user = useAuth((state) => state.user);

  const [amount, setAmount] = useState(0);

  return (
    <Box>
      {user.Mode !== 'API' && <Header headerText={'Dashboard'} breadcrumbs={false} />}
      <Grid
        h="100%"
        p={user.Mode === EnumMode.API ? 0 : 2}
        gap="8px"
        templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
      >
        <GridItem>
          <MerchantNotifications user={user} setTabIndex={setTabIndex} setOptions={setOptions} />
        </GridItem>
        <GridItem>
          <Flex
            borderRadius={'4px'}
            border={'1px solid #F2F3F3'}
            bg={'white'}
            p={'24px'}
            flexDir={'column'}
            height={'100%'}
            overflow={'auto'}
          >
            <Flex h={'44px'}>
              <Icon variant={'RevenueChart'} size={11} />
              <Flex flexDir={'column'} ml={'12px'} justifyContent={'center'}>
                <Text variant={'heading05Bold'} color={'secondary.800'} mb={'4px'}>
                  Revenue Chart
                </Text>
              </Flex>
            </Flex>
            <Divider my={'16px'} variant={'fade'} />
            <Flex h={'316px'}>
              <RevenueChart />
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          <PendingSettlement amount={amount} setAmount={setAmount} />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default React.memo(MerchantDashboard);
