
import { Box, Text, Flex, useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState, useCallback, useEffect } from 'react';

import CRUD, {
  useCRUD,
  Table,
  Filters,
  NumberFormat,
  FilterInputDateSelect,
  FilterInputSelect,
  BtnRefresh,
  } from 'Library/CRUD';

import ReportsPDFModal from './ReportsPDFModal';
  
import { useStore, useAuth, useDB } from 'store';

import { Alert, Button } from 'components';
import { useAlert } from 'utils';

import { FaFileDownload } from "react-icons/fa";

import moment from 'moment';
import ReportsSettlementDrilldown from './ReportsSettlementDrilldown';

export const ReportsSettlement = () => {
  const db = useDB();
  const user = useAuth((state) => state.user);

  const multiMerchant = user.Type === 'ADMIN' ? true : false;
  const [ merchantCode, setMerchantCode ] = useState<any>('');
  const timezone = user.TimeZone;

  const lookup = useStore((state) => state.lookup);
  const [alert, setAlert] = useAlert();

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [fileName, setFileName] = useState<string>('');

  const merchantSelect = Object.keys(lookup.Merchant).sort().map((m: any) => {
      return(
        {
          value: lookup.Merchant[m].Code,
          label: lookup.Merchant[m].Code + " - " + lookup.Merchant[m].Name
        }
      )
    }
  )

  const crud = useCRUD({
    id: 'settlementReport',
    title: user.Mode === 'API' ? '' : 'Day Report',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    filter: {
      selectedDate: new Date(), 
      merchant: '',
    },
    fetch: async (qry: string) => {
      const result = await db.axios({
        server: 'PAYGATE',
        method: 'POST',
        url: '/api/v1/reports/PaymentDay/',
        data: {
          'Format': 'json',
          'Delivery': 'download',
          'DeliveryTo': '',
          'Params': {
              'MerchantCode': qry.split('&')[1].split('=')[1],
              'FromDate': moment(qry.split('=')[1]).isValid() ?
                moment(qry.split('=')[1]).format("YYYY-MM-DD")
                :
                moment(qry.split('=')[1].split('&')[0]).format("YYYY-MM-DD")
          }
        }
      });

      const res: any = result.data.data;
      res.BroughtForwardRows !== null && (
        res.BroughtForwardRows = res.BroughtForwardRows.map((r: any) => ({
          ...r,
          Date: timezone
            ? moment.utc(r.Date).tz(timezone).format("ddd MMM D, YYYY kk:mm z")
            : moment(r.Date).format("ddd MMM D, YYYY kk:mm z"),
        }))
      );
      res.CarriedForwardRows !== null && (
        res.CarriedForwardRows = res.CarriedForwardRows.map((r: any) => ({
          ...r,
          Date: timezone
            ? moment.utc(r.Date).tz(timezone).format("ddd MMM D, YYYY kk:mm z")
            : moment(r.Date).format("ddd MMM D, YYYY kk:mm z"),
        }))
      );
      res.DayRows !== null && (
        res.DayRows = res.DayRows.map((r: any) => ({
          ...r,
          Date: timezone
            ? moment.utc(r.Date).tz(timezone).format("ddd MMM D, YYYY kk:mm z")
            : moment(r.Date).format("ddd MMM D, YYYY kk:mm z"),
        }))
      );
      res.EarlierRefundSettlements !== null && (
        res.EarlierRefundSettlements = res.EarlierRefundSettlements.map((r: any) => ({
          ...r,
          Created: timezone
            ? moment.utc(r.Created).tz(timezone).format("ddd MMM D, YYYY")
            : moment(r.Created).format("ddd MMM D, YYYY kk:mm z"),
          Date: timezone
            ? moment.utc(r.Date).tz(timezone).format("ddd MMM D, YYYY")
            : moment(r.Date).format("ddd MMM D, YYYY kk:mm z"),
          Updated: timezone 
            ? moment.utc(r.Updated).tz(timezone).format("ddd MMM D, YYYY")
            : moment(r.Updated).format("ddd MMM D, YYYY kk:mm z"),
        }))
      );
      res.hasOwnProperty('Settlements') && res.Settlements !== null && (
        res.Settlements = res.Settlements.map((r: any) => ({
          ...r,
          Date: timezone
            ? moment.utc(r.Settlements).tz(timezone).format("ddd MMM D, YYYY")
            : moment(r.Settlements).format("ddd MMM D, YYYY"),
        }))
      );

      let resArray: any[] = [res]

      return resArray;
    },
    filterKey: 'merchant',
  });
  const { updateFilter } = crud;

  useEffect(() => {
    if (user.Merchant && merchantCode !== user.Merchant) {
      setMerchantCode(user.Merchant);
      updateFilter({ merchant: user.Merchant });
    }
  }, [user.Merchant, merchantCode, setMerchantCode, updateFilter]);

  const useDownloadPDF = () => {
    const [isLoadingPDF, setIsLoadingPDF] = useState(false);
    const downloadPDFAsync = async () => {
        setIsLoadingPDF(true);
        const result = await db.axios(
          {
            server: 'PAYGATE',
            method: 'POST',
            url: '/api/v1/reports/PaymentDay/',
            responseType: 'arraybuffer',
            data: {
              'Format': 'pdf',
              'Delivery': 'download',
              'DeliveryTo': '',
              'Params': {
                  'MerchantCode': crud.filter.merchant,
                  'FromDate': moment(crud.filter.selectedDate).format("YYYY-MM-DD")
              }
            }
          }
        )
        if (!result?.error) {
          const blob = new Blob([result.data], {type: 'application/pdf'});
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          setFileUrl(link.href);
          link.download = "day-report-" +
                          moment(crud.filter.selectedDate).format("YYYY-MM-DD") +
                          ".pdf";
          setFileName(link.download);
          onOpen();

        } else {
          console.log(result);
        }
        setIsLoadingPDF(false);

        return;
    };

    return {isLoadingPDF, downloadPDFAsync};
  };

  const {isLoadingPDF, downloadPDFAsync} = useDownloadPDF();

  const onDownload = useCallback(
    async () => {
      const result = await downloadPDFAsync();
      return result;
    },
    [downloadPDFAsync],
  );

  const totalsDataHandles: Array<string> = useMemo(() => [
    "BFTotals",
    "DTTotals",
    "ExpectedSettleTotals",
    "ActualSettleTotals",
    "CFTotals"
  ], []);

  const columnsArray = useMemo(() =>
  new Map(totalsDataHandles.map((handle: string) => [
    handle,
    [
      {
        name: '',
        selector: (row: any) => {"TOTAL"},
        format: (row: any) => <b>{"TOTAL"}</b>,
        sortable: false,
      },
      {
        name: 'CREDIT',
        selector: (row: any) => row[handle].CreditTotal,
        sortable: false,
        format: (row: any) => <NumberFormat color={'green'} amount={row[handle].CreditTotal} />,
        width: '120px',
        right: true,
      },
      {
        name: 'DEBIT',
        selector: (row: any) => row[handle].DebitTotal,
        sortable: false,
        format: (row: any) => <NumberFormat color={'red'} amount={row[handle].DebitTotal} />,
        width: '120px',
        right: true,
      },
      {
        name: 'FEE',
        selector: (row: any) => row[handle].FeeTotal,
        sortable: false,
        format: (row: any) => <NumberFormat color={'red'} amount={row[handle].FeeTotal} />,
        width: '100px',
        right: true,
      },
      {
        name: 'BALANCE',
        selector: (row: any) => row[handle].BalanceTotal,
        sortable: false,
        format: (row: any) => <NumberFormat color={'green'} amount={row[handle].BalanceTotal} />,
        width: '120px',
        right: true,
      },
    ]
  ])), [totalsDataHandles]);

  let columnsBroughtForward = useMemo(() =>
    columnsArray.get("BFTotals"), [columnsArray],
  );
  let columnsTransactions = useMemo(() =>
    columnsArray.get("DTTotals"), [columnsArray],
  );
  let columnsBroughtForwardAndTotals = useMemo(() =>
    columnsArray.get("ExpectedSettleTotals"), [columnsArray],
  );
  let columnsSettlementTotals = useMemo(() =>
    columnsArray.get("ActualSettleTotals"), [columnsArray],
  );
  let columnsTransactionsAfterCutoff = useMemo(() =>
    columnsArray.get("CFTotals"), [columnsArray],
  );

  const filter = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          `200px 260px ${multiMerchant ? '200px' : ''} 170px 280px`,
        ]}
      >
        <FilterInputDateSelect
          value={crud.filter.selectedDate}
          onChange={(date: Date) => {
            updateFilter({
              selectedDate: date,
            });
          }}
          maxDate={new Date()}
          minDate={new Date('2023-07-11')}
        />
        <Flex p={'4px'}>
          <Button
            isDisabled={crud?.data?.length === 0}
            loadingText={"Downloading..."}
            isLoading={isLoadingPDF}
            colorScheme={"blue"}
            label={"Download PDF"}
            variant="solid"
            width="100%"
            leftIcon={crud?.data?.length !== 0 ? 
              <FaFileDownload color="white" /> :
              <FaFileDownload color="rgba(255, 255, 255, 0.32)" />}
            onClick={onDownload}
          />
        </Flex>
        {multiMerchant && (
          <FilterInputSelect
            placeholder="Merchant..."
            defaultValue={crud.filter.merchant}
            width="100%"
            onChange={(val: string) => {
              setMerchantCode(val);
              updateFilter({ merchant: val });
            }}
            options={merchantSelect}
          />
        )}
        <Flex p={'4px'}>
          <BtnRefresh crud={crud} cascade={true}/>
        </Flex>
      </Filters>
    )
  }, [crud, isLoadingPDF, onDownload, multiMerchant, merchantSelect, updateFilter, setMerchantCode]);

    return (
      <Box fontFamily={'Public Sans'} h="100%" lineHeight={1.2} p={1}>
        <CRUD id="settlementReport" crud={crud} filters={filter}>
          <Alert alert={alert} setAlert={setAlert} />
        </CRUD>
          <Box paddingTop={'30px'}/>
          {crud?.filter?.merchant === null || crud?.filter?.merchant === '' ? (
          <Text variant={'heading06Bold'} color={'secondary.800'} mb={'4px'}>
            {"Please select a merchant..."}
          </Text>
          ) : (
            <>
          <Text variant={'heading06Bold'} color={'secondary.800'} mb={'4px'}>
            {"Transactions Brought Forward (Since cut off @ " + crud?.data[0]?.LastCutoffTime + ")"}
          </Text>
          <Table
            id="BFTable"
            crud={crud}
            columns={columnsBroughtForward}
            responsive
            pagination={false}
            expandableRows={crud?.data[0]?.BroughtForwardRows !== null}
            expandableRowDisabled={(row: any) => row.count <= 1}
            expandableRowsComponent={ReportsSettlementDrilldown}
            expandableRowsComponentProps={{
              pl: '45px',
              filter: crud.filter,
              data: crud.data[0],
              dataHandle: 'BroughtForwardRows',
              type: 'transactions',
            }}
            striped={false}
          />
          <Box paddingTop={'30px'}/>
          <Text variant={'heading06Bold'} color={'secondary.800'} mb={'4px'}>
            {"Transactions for " + crud?.data[0]?.Day}
          </Text>
          <Table
            id="TTable"
            crud={crud}
            columns={columnsTransactions}
            responsive
            pagination={false}
            expandableRows={crud?.data[0]?.DayRows !== null}
            expandableRowDisabled={(row: any) => row.count <= 1}
            expandableRowsComponent={ReportsSettlementDrilldown}
            expandableRowsComponentProps={{
              pl: '45px',
              filter: crud.filter,
              data: crud.data[0],
              dataHandle: 'DayRows',
              type: 'transactions',
            }}
            striped={false}
          />
          <Box paddingTop={'30px'}/>
          <Text variant={'heading06Bold'} color={'secondary.800'} mb={'4px'}>
            {"Combined Brought Forward and Day Totals"}
          </Text>
          <Table
            id="CTable"
            crud={crud}
            columns={columnsBroughtForwardAndTotals}
            responsive
            pagination={false}
            expandableRows={crud?.data[0]?.Settlements !== null}
            expandableRowDisabled={(row: any) => row.count <= 1}
            expandableRowsComponent={ReportsSettlementDrilldown}
            expandableRowsComponentProps={{
              pl: '45px',
              filter: crud.filter,
              data: crud.data[0],
              dataHandle: 'Settlements',
              type: 'note',
            }}
            striped={false}
          />
          <Box paddingTop={'30px'}/>
          <Text variant={'heading06Bold'} color={'secondary.800'} mb={'4px'}>
            {"Settlement Totals " +
            (crud?.data[0]?.ActualSettleDate.split(", ")[1] !== "0001" ?
            "On " + crud?.data[0]?.ActualSettleDate : "")}
          </Text>
          <Table
            id="STable"
            crud={crud}
            columns={columnsSettlementTotals}
            responsive
            pagination={false}
            expandableRows={crud?.data[0]?.EarlierRefundSettlements !== null}
            expandableRowDisabled={(row: any) => row.count <= 1 || row === null}
            expandableRowsComponent={ReportsSettlementDrilldown}
            expandableRowsComponentProps={{
              pl: '45px',
              filter: crud.filter,
              data: crud.data[0],
              dataHandle: 'EarlierRefundSettlements',
              type: 'note',
            }}
            striped={false}
          />
          <Box paddingTop={'30px'}/>
          <Text variant={'heading06Bold'} color={'secondary.800'} mb={'4px'}>
            {"Transactions After Cutoff, Carried Forward"}
          </Text>
          <Table
            id="TCTable"
            crud={crud}
            columns={columnsTransactionsAfterCutoff}
            responsive
            pagination={false}
            expandableRows={crud?.data[0]?.CarriedForwardRows !== null}
            expandableRowDisabled={(row: any) => row.count <= 1}
            expandableRowsComponent={ReportsSettlementDrilldown}
            expandableRowsComponentProps={{
              pl: '45px',
              filter: crud.filter,
              data: crud.data[0],
              dataHandle: 'CarriedForwardRows',
              type: 'transactions',
            }}
            striped={false}
          />
    {isOpen &&
    <ReportsPDFModal
      fileUrl={fileUrl}
      fileName={fileName}
      isOpen={isOpen}
      onClose={onClose}
      title='Settlements Report'
    />
    }
    </>
          )} 
      </Box>
    );
};

export default React.memo(ReportsSettlement)
