import React, { useEffect, useMemo } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';

import CRUD, { useCRUD, Table, NumberFormat } from 'Library/CRUD';
import { useAuth, useDB } from 'store';
import { Text, Divider, Icon, Alert } from 'components';
import { useAlert } from 'utils';

interface ITopMerchants{
  dayRange: number;
}

const TopMerchants = (props: ITopMerchants) => {
  const { dayRange } = props;
  const user = useAuth((state) => state.user);

  const [alert, setAlert] = useAlert();

  const crud = useCRUD({
    id: 'transaction',
    title: '',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    waitRefresh: true,
    filter: {
      dayRange: dayRange,
    },
    fetch: async (qry: string) => {
      // if (!filter.query) return [] //Wait until there is a filter
      let result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/v1/analytics/TopMerchants/${qry}`,
      });
      const topMerchants = result.data.data.length >= 10 ? result.data.data.slice(0, 10) : result.data.data

      return topMerchants;
    },
  });

  useEffect(() => {
    crud.refresh({
      message: 'CHANGE USER ID',
      filter: { merchant: user.Merchant },
    });
    // eslint-disable-next-line
  }, [user.Merchant]);

  const columns = useMemo(
    () => [
      {
        name: 'Rank',
        selector: (row: any) => row['Rank'],
        sortable: true,
        width: '80px'
      },
      {
        name: 'Code',
        selector: (row: any) => row['Code'],
        sortable: true,
        width: '90px',
      },
      {
        name: 'Name',
        selector: (row: any) => row['Name'],
        format: (row: any) => <Text>{row.Name}</Text>,
        sortable: true,
      },
      {
        name: 'Revenue',
        selector: (row: any) => row['Revenue'],
        sortable: true,
        format: (row: any) => <NumberFormat amount={row.Revenue} format='$0,00.00' />,
        right: true,
        width: '145px'
      },
    ],
    [],
  );

  return (
    <Flex
      borderRadius={'4px'}
      border={'1px solid #F2F3F3'}
      bg={'white'}
      p={'24px'}
      flexDir={'column'}
      height={'100%'}
      overflow={'auto'}
    >
      <Flex h={'44px'}>
        <Icon variant={'PendingSettlement'} size={11} />
        <Flex flexDir={'column'} ml={'12px'}>
          <Text variant={'heading05Bold'} color={'secondary.800'} mb={'4px'}>
           Merchant Performance Rankings 
          </Text>
          <Spacer />
          {/* <Text variant={'labelSmallRegular'} color={'secondary.700'}>
            {numeral(amount).format('$0,00.00')}
          </Text> */}
        </Flex>
      </Flex>
      <Divider my={'16px'} variant={'fade'} />
      <Box fontFamily={'Roboto Condensed'} overflow={'hidden'}>
        <CRUD
          id="transactions"
          crud={crud}
        >
          <Alert alert={alert} setAlert={setAlert} />
          <Table
            pagination={false}
            id="bank_table"
            crud={crud}
            columns={columns}
            responsive={true}
            striped={false}
          ></Table>
        </CRUD>
      </Box>
    </Flex>
  );
};

export default React.memo(TopMerchants);
