import { extendTheme } from '@chakra-ui/react';
import { breakpoints } from './breakpoints';
import * as colorSchemes from './colorSchemes';

import {
  fontSizes,
  lineHeights,
  buttonTheme,
  formErrorTheme,
  formLabelTheme,
  formTheme,
  textTheme,
  inputTheme,
  cardTheme,
  menuTheme,
  dateRangeTheme,
  tooltipTheme,
  dividerTheme,
  sidebarTheme,
  avatarTheme,
  tabsTheme,
  modalTheme,
  selectTheme,
} from './components';

import '@fontsource/roboto';
import '@fontsource/roboto-condensed';
import '@fontsource/public-sans/100.css';
import '@fontsource/public-sans/200.css';
import '@fontsource/public-sans/300.css';
import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/600.css';
import '@fontsource/public-sans/700.css';
import '@fontsource/public-sans/800.css';
import '@fontsource/public-sans/900.css';

const theme = extendTheme({
  // Add Custom Semi-Condensed Fonts
  fonts: {
    heading: 'Public Sans',
    body: 'Public Sans',
    condensed: `'Roboto Condensed', sans-serif`,
  },

  breakpoints,
  sizes: {
    11: '2.75rem',
  },
  fontSizes,
  lineHeights,
  colors: {
    ...colorSchemes,
  },
  components: {
    Card: {
      ...cardTheme,
    },
    Tabs: {
      ...tabsTheme,
    },
    Button: {
      ...buttonTheme,
    },
    Input: {
      ...inputTheme,
    },
    FormLabel: {
      ...formLabelTheme,
    },
    Form: {
      ...formTheme,
    },
    FormError: {
      ...formErrorTheme,
    },
    Text: {
      ...textTheme,
    },
    Menu: {
      ...menuTheme,
    },
    DateRange: {
      ...dateRangeTheme,
    },
    Tooltip: {
      ...tooltipTheme,
    },
    Divider: {
      ...dividerTheme,
    },
    Sidebar: {
      ...sidebarTheme,
    },
    Avatar: {
      ...avatarTheme,
    },
    Modal: {
      ...modalTheme,
    },
    Select: {
      ...selectTheme,
    },
  },
});
export default theme;
