/*************************************************************************************
 * Select - Select Control based on react-select using chakra-react-select library ***
 *************************************************************************************
 Note: By default this will set width to 100% of the container and set protal as tru so it overlaps other regions
 This will also return value as the objects value and not {value,label} (Note: The full object is returned to onChange callback as 2nd argument)
 */

import React, { useState, useEffect } from 'react';
import { Select as ReactSelect, ChakraStylesConfig } from 'chakra-react-select';

export interface ISelectValue {
  value: string | number;
  label: string;
}

export const Select = (props: any) => {
  const { options, defaultValue, onChange, w = '100%', portal = true, ...o } = props;
  const [value, setValue] = useState(props && props.value ? props.value : null);

  //IF DEFAULT VALUE SUPPLIED THEM CONVERT TO {value/label} object based on options supplied
  useEffect(() => {
    if (defaultValue) {
      let opt = options.filter((rec: any) => rec.value === defaultValue);
      if (opt) setValue(opt);
    }
  }, [defaultValue, options]);

  const chakraStyles: ChakraStylesConfig = {
    container: (provided) => ({
      ...provided,
      w: w,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#EDF2F7',
      //zIndex: 1,
      border: 0,
    }),
    menuList: (provided) => {
      return {
        ...provided,
        backgroundColor: 'aliceblue',
      };
    },
  };
  let styles = props && props.styles ? props.styles : {};
  if (portal) {
    styles.menuPortal = (provided: any) => ({ ...provided, zIndex: 100 });
  }
  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={(vals: ISelectValue) => {
        if (onChange) {
          props.onChange(vals.value, vals);
        }
      }}
      chakraStyles={chakraStyles}
      styles={styles}
      {...o}
    />
  );
};
