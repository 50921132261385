import React, { useMemo } from 'react';
import { Box, GridItem } from '@chakra-ui/react';
import { FaFlag } from 'react-icons/fa';

import CRUD, {
  useCRUD,
  Editor,
  Table,
  FilterInputSearch,
  Filters,
  BtnRefresh,
  BtnInactive,
  BtnAddRecord,
  MultiLineHeader,
  InactiveFlag,
  FilterInputSelect,
} from 'Library/CRUD';
import { getSelect } from 'Library';
import { useStore, useDB } from 'store';
import { Alert } from 'components';
import { useAlert } from 'utils';

import { UserEdit } from './UserEdit';

export const Users = React.memo(() => {
  const [alert, setAlert] = useAlert();

  const lookup = useStore((state) => state.lookup);
  const merchantSelect = getSelect(lookup.Merchant, {
    keyField: 'code',
    label: 'code',
    selectAll: { value: '', label: 'All' },
  });

  // eslint-disable-next-line
  const crud = useCRUD({
    id: 'users',
    title: 'User Accounts',
    hasEditor: true,
    keyField: 'id',
    setAlert: setAlert,
    editor: {
      title: 'User',
    },
    fetch: async (qry: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/users/fetch${qry}`,
      });
      return result.data;
    },
    create: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/users/create`,
        data: rec,
      });
      return result;
    },
    read: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/users/read/${key}`,
      });
      return result;
    },
    update: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/users/create`,
        data: rec,
      });
      return result;
    },
    delete: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'DELETE',
        url: `/api/management/users/delete/${key}`,
      });
      return result;
    },
  });

  let columns = useMemo(
    () => [
      {
        name: <FaFlag />,
        selector: (row: any) => row['inactive'],
        format: (row: any) => <InactiveFlag flag={row.inactive} />,
        width: '25px',
        omit: !crud.inactive,
      },
      {
        name: 'ID',
        selector: (row: any) => row['id'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Name',
        selector: (row: any) => row['name'],
        sortable: true,
      },
      {
        name: 'Email',
        selector: (row: any) => row['email'],
        sortable: true,
      },
      {
        name: 'Merchant ID',
        selector: (row: any) => row['merchant_id'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Type',
        selector: (row: any) => row['type'],
        sortable: true,
        width: '100px',
      },
      {
        name: <MultiLineHeader heading={['Expiry', '(secs)']} align="right" />,
        selector: (row: any) => row['token_expiry_seconds'],
        width: '100px',
        right: true,
      },
    ],
    [crud.inactive],
  );

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 1fr 180px 150px 150px 150px',
        ]}
      >
        <FilterInputSearch crud={crud} />
        <GridItem />
        <FilterInputSelect
          placeholder="Merchant..."
          defaultValue={crud.filter.merchant}
          width="150px"
          onChange={(val: string) => {
            crud.setFilter({ ...crud.filter, merchant: val });
          }}
          options={merchantSelect}
        />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAddRecord crud={crud} />
      </Filters>
    );
    // eslint-disable-next-line
  }, [crud.filter, crud.inactive]);

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="users" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Editor crud={crud}>
          <UserEdit id="merchant-edit" crud={crud} />
        </Editor>
        <Table
          id="merchant-table"
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </Box>
  );
});
