import { format, parse, parseISO } from 'date-fns';

export type DateFormat =
  | 'dd/MM/yyyy'
  | 'yyyy-MM-dd'
  | 'd MMM yyyy'
  | 'd MMM'
  | 'd MMM yyyy h:mm'
  | 'd'
  | 'E'
  | 'EEEE'
  | 'MMM yyyy'
  | 'MMM d, yyyy'
  | 'EEE d'
  | 'EEE, d MMM'
  | 'EEE, d MMM h:mm aa'
  | 'EEE, MMM d yyyy'
  | 'EEE, MMMM d, yyyy'
  | 'EEE, d MMM yyyy'
  | 'EEE, d MMMM yyyy'
  | 'EEE, d MMM yyyy h:mm aa'
  | 'EEE, dd MMM yyyy'
  | 'EEE, dd MMMM yyyy'
  | 'EEE, MMMM dd yyyy'
  | 'EEEE, MMMM dd yyyy'
  | 'EEE d MMM'
  | 'd MMM h:mm a'
  | 'MMMM yyyy'
  | 'EEE dd MMM yyyy'
  | 'yyyy-MM-ddTHH:mm:ss'
  | 'dd MM yyyy'
  | 'dd MMM yyyy'
  | 'dd MMM yyyy HH:mm aa'
  | 'HH:mm MMM dd yyyy'
  | 'd MMM yyyy h:mm a'
  | 'MMMM d'
  | 'MMM dd'
  | 'dd'
  | 'MMMM'
  | 'MMM d'
  | 'dd MMM yyyy h:mm aa';

export const convertDateFromTimePicker = (dateValue: string | Date, timeValue: string) => {
  const times = timeValue.split(':');
  const hours = times[0];
  const minutes = times[1];

  const date =
    typeof dateValue === 'string' ? parseDateStringEqualsToDatabaseFormat(dateValue) : dateValue;
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  return date;
};

export const formatDateParsed = (
  dateValue: string | undefined,
  dateFormat: DateFormat = 'dd MM yyyy',
): string => {
  if (!dateValue) return '';
  return format(parseISO(dateValue), dateFormat);
};

export const formatChargeDate = (
  dateValue: string | undefined,
  dateFormat: DateFormat = 'MMM d, yyyy',
): string => {
  if (!dateValue) return '';
  return format(parseISO(dateValue), dateFormat);
};

export const formatDateString: (dateValue: Date | string) => string = (dateValue) => {
  if (typeof dateValue === 'string') return dateValue;
  return format(dateValue, 'yyyy-MM-dd');
};

export const formatDayShortString: (dateValue: Date) => string = (dateValue) => {
  return format(dateValue, 'MMM d');
};

export const formatDateStringEqualsToDatabaseFormat: (dateValue: Date) => string = (dateValue) => {
  return format(dateValue, `yyyy-MM-dd`) + 'T' + format(dateValue, `HH:mm:ss`);
};

export const formatDateStringEqualsToDatabaseFormatNoTime: (dateValue: Date) => string = (
  dateValue,
) => {
  return format(dateValue, `yyyy-MM-dd`) + 'T00:00:00';
};

export const formatNullableDateStringEqualsToDatabaseFormatNoTime: (
  dateValue: Date | undefined,
) => string = (dateValue) => {
  if (!dateValue) {
    return '';
  } else {
    return format(dateValue, `yyyy-MM-dd`) + 'T00:00:00';
  }
};

export const formatDateStringLongDateWithTime: (dateValue: Date) => string = (dateValue) => {
  return formatDate(dateValue, 'EEE, d MMM yyyy h:mm aa');
};

export const parseDateString: (dateString: string) => Date = (dateString) => {
  return parse(dateString, 'yyyy-MM-dd', new Date());
};

export const parseDateStringEqualsToDatabaseFormat: (dateString: string) => Date = (dateString) => {
  return parse(dateString, "yyyy-MM-dd'T'HH:mm:ss", new Date());
};

export const toDateWithoutTime: (dateValue: Date) => Date = (dateValue) => {
  const dateValueIsDate = dateValue ? new Date(dateValue) : new Date();
  const d = new Date(
    Date.UTC(dateValueIsDate.getFullYear(), dateValueIsDate.getMonth(), dateValueIsDate.getDate()),
  );
  d.setHours(0, 0, 0, 0);

  return d;
};

export const formatDateSlash: (dateValue: Date) => string = (dateValue) => {
  return format(dateValue, `dd'/'MM'/'yyyy`);
};

export const isDatePartEqual = (first: Date, second: Date) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

export const formatDate: (dateValue: Date, dateFormat?: DateFormat) => string = (
  dateValue,
  dateFormat = 'dd/MM/yyyy',
) => {
  return format(dateValue, dateFormat);
};

export function ticksToDate(ticks: number) {
  const ticksToMicrotime = ticks / 10000;
  const epochMicrotimeDiff = Math.abs(new Date(0, 0, 1).setFullYear(1));
  return new Date(ticksToMicrotime - epochMicrotimeDiff);
}

export const getMaxValidSqlDate = () => {
  return new Date('9999-12-31');
};
