import Routes from './Routes';
export default Routes;

export * from './Dashboard';
export * from './Management';
export * from './Settlements';
export * from './Reports';
export * from './Transactions';
export * from './Login';
export * from './Embedded';
