import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import { Search } from 'components/Search';

export const FilterInputSearch = (props) => {
  const { crud } = props;

  const [searchText, setSearchText] = useState('');
  const [shouldSearch, setShouldSearch] = useState(false);
  useEffect(() => {
    if (shouldSearch) {
      if (searchText.length > 2) {
        crud.setSearchText(searchText);
      } else {
        crud.setSearchText('');
      }
      setShouldSearch(false);
    }
  }, [searchText, shouldSearch, crud]);

  return (
    <Box marginBottom="2" p={'4px'}>
      <Search
        placeholder={'Search Text (min 3 chars)'}
        onButtonClick={() => setShouldSearch(true)}
        onEnter={() => setShouldSearch(true)}
        onClear={() => setShouldSearch(true)}
        onChange={setSearchText}
        value={searchText}
      />
    </Box>
  );
};
